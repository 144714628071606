import firebase from "firebase/app";
import { New } from "./types"

export class NewConverter implements firebase.firestore.FirestoreDataConverter<New> {
    toFirestore(newEntry: New): firebase.firestore.DocumentData {
        throw new Error('not implemented');  
    }
    fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>, options: firebase.firestore.SnapshotOptions): New {
        const data = snapshot.data();
        return new New({
            id: snapshot.id,   
            title: data.title,
            description: data.description,
            link: data.link,            
            date: data.date.toDate(),                 
        })
    }

}
