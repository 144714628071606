import firebase from 'firebase/app';
import { CollectionOptions } from '.';
import { CollectionActions, CollectionMutations, CollectionState } from './types'

export default function actionsFactory(state: CollectionState, mutations: CollectionMutations, collectionOptions: CollectionOptions) : CollectionActions {
    async function fetch(options: { filters: any, itemsPerPage: number | undefined} | null): Promise<void> {
        try {
            mutations.MUTATION_COLLECTION_SET_LOADING(true);

            if(options) {
                if(options.itemsPerPage) {
                    mutations.MUTATION_COLLECTION_SET_ITEMSPERPAGE(options.itemsPerPage > 1 ?options.itemsPerPage : 1);
                    if(options.itemsPerPage == 1) {
                        return;
                    }
                }                
                
                if(options.filters) {
                    mutations.MUTATION_COLLECTION_RESET_METADATA(true);
                    mutations.MUTATION_COLLECTION_SET_FILTERS(options.filters);                    
                }
            }

            const query = prepareQuery(collectionOptions.queryFactory, state);
            const snapshot = await (collectionOptions.converter ? query.withConverter(collectionOptions.converter).get() : query.get());            

            const items: any[] = [];
            for(let i = 0; i < snapshot.size; i++) {
                const doc = snapshot.docs[i];
                if(collectionOptions.itemHandler) {
                    const newItems = await collectionOptions.itemHandler(doc, options ? options.filters : null);
                    items.push(...newItems);
                } else {
                    items.push(doc.data());
                }  
            }
                     

            let first = null;
            let last = null;
            if (snapshot.size > 0) {
                first = snapshot.docs[0];

                if (state.lastPage <= state.page) {
                    if (snapshot.size == state.itemsPerPage + 1) {
                        last = snapshot.docs[snapshot.size - 2];
                        items.pop();
                    }
                } else {
                    last = snapshot.docs[snapshot.size - 1];
                }
            }            
            mutations.MUTATION_COLLECTION_SET_FIRST(first);
            mutations.MUTATION_COLLECTION_SET_LAST(last);
            mutations.MUTATION_COLLECTION_SET_ITEMS(items);

        } finally {
            mutations.MUTATION_COLLECTION_SET_LOADING(false);
        }
    }

    function prepareQuery(queryFactory: (state: CollectionState) => firebase.firestore.Query, state: CollectionState) {
        let query = queryFactory(state);

        if (state.lastPage <= state.page) {
            if (null != state.last) {
                query = query.startAfter(state.last);
            }
            query = query.limit(state.itemsPerPage + 1);
        } else {
            if (null != state.first) {
                query = query.endBefore(state.first);
            }
            query = query.limitToLast(state.itemsPerPage);
        }

        return query;
    }

    async function goToNext(): Promise<void> {        
        mutations.MUTATION_COLLECTION_SET_LASTPAGE(state.page);
        mutations.MUTATION_COLLECTION_SET_PAGE(state.page + 1);
        await fetch(null);
    }

    async function goToPrevious(): Promise<void> {
        mutations.MUTATION_COLLECTION_SET_LASTPAGE(state.page);
        mutations.MUTATION_COLLECTION_SET_PAGE(state.page < 1 ? 1 : state.page - 1);
        await fetch(null);
    }

    async function orderBy(name: string, desc: boolean): Promise<void> {
        mutations.MUTATION_COLLECTION_RESET_METADATA(false);
        mutations.MUTATION_COLLECTION_SET_SORTBY(name);
        mutations.MUTATION_COLLECTION_SET_SORTDESC(desc);
        await fetch(null);
    }

    async function refresh(): Promise<void> {
        const currentSortBy = state.sortBy;
        const currentSortDesc = state.sortDesc;
        mutations.MUTATION_COLLECTION_RESET_METADATA(false);
        mutations.MUTATION_COLLECTION_SET_SORTBY(currentSortBy);
        mutations.MUTATION_COLLECTION_SET_SORTDESC(currentSortDesc);
        await fetch(null);
    }

    async function reset(): Promise<void> {
        mutations.MUTATION_COLLECTION_RESET_METADATA(true);
    }

    return {
        fetch,
        goToNext,
        goToPrevious,
        refresh,
        reset,
        orderBy
    }
}