
import firebase from 'firebase/app';
import stateFactory from './state'
import gettersFactory from './getters'
import actionsFactory from './actions'
import mutationFactory from "./mutations"
import { CollectionActions, CollectionGetters, CollectionMutations, CollectionState } from "./types"
import { toRefs, ToRefs } from '@vue/composition-api'

let state: CollectionState;
let getters: CollectionGetters;
let mutations: CollectionMutations;
let actions: CollectionActions;

export interface CollectionOptions{
    sortBy: string,
    sortDesc: boolean | undefined,
    filters: any,
    queryFactory: (state: CollectionState) => firebase.firestore.Query,
    converter: any,
    itemHandler: ((item: any, filters: any) => Promise<any[]>) | undefined
}

export interface CollectionComposable extends ToRefs<CollectionState>, CollectionGetters, CollectionActions {

}

export default function useCollection(options: CollectionOptions): CollectionComposable {
    state = stateFactory(options.sortBy, options.sortDesc || false, options.filters);
    getters = gettersFactory(state);
    mutations = mutationFactory(state);
    actions = actionsFactory(state, mutations, options);

    return {
        ...toRefs(state),
        ...getters!,
        ...actions!
    }
}