import { reactive } from "@vue/composition-api";
import { CollectionState } from "./types";

export default function stateFactory(sortBy: string, sortDesc:boolean, filters: any): CollectionState {
    const state: CollectionState = {
        items: [],
        first: null,
        last: null,
        page: 1,
        lastPage: 1,
        defaultSortBy: sortBy,
        sortBy,
        defaultSortDesc: sortDesc,
        sortDesc,
        defaultFilters: filters,
        filters,
        loading: false,
        itemsPerPage: 0,
    }
    return reactive(state);
}