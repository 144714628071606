import { UserState, UserMutations, User } from './types';

export default function mutationFactory(state: UserState): UserMutations {
    function MUTATION_SET_CURRENT(current: User | null)  {
        state.user = current;
    }

    return {
        MUTATION_SET_CURRENT
    }
}