



























































































import Vue from "vue";
import { defineComponent, Ref, ref, watch } from "@vue/composition-api";
import CollectionViewer from "@/components/CollectionViewer.vue";
import { CollectionState } from "@/composables/collection/types";
import i18n from "@/plugins/i18n";
import firebaseService from "@/services/firebase";
import { TodoConverter } from "@/composables/todo/converters";
import moment from "moment";
import useCollection from "@/composables/collection";
import { Todo, TodoEditable } from "@/composables/todo/types";
import DetailDialog from "@/components/DetailDialog.vue";
import TaskEditor from "@/components/TaskEditor.vue";
import { v4 as uuid } from "uuid";
import useUser from "@/composables/user";
export default defineComponent({
  components: {
    CollectionViewer,
    DetailDialog,
    TaskEditor,
  },
  setup(props) {
    const { user } = useUser();

    const todoEditorDialog = ref(false);
    const todoEditable: Ref<TodoEditable | null> = ref(null);
    const currentTodo: Ref<Todo | null> = ref(null);

    const editTodo = async (todo: Todo) => {
      todoEditable.value = todo.edit();
      todoEditorDialog.value = true;
    };
    const updateTodo = async () => {
      await todoEditable.value!.updateOriginal();
      await collectionComposable.refresh();
    };

    const todoState = ref(2);
    const todoAssignedTo = ref(1);

    watch([() => todoState.value, () => todoAssignedTo.value], async () => {
      await collectionComposable.fetch({
        filters: {
          todoState: todoState.value,
          todoAssignedTo: todoAssignedTo.value,
        },
        itemsPerPage: undefined,
      });
    });

    const collectionComposable = useCollection({
      sortBy: "createdon",
      sortDesc: true,
      filters: {
        todoState: 2,
        todoAssignedTo: 1,
      },
      queryFactory: (state: CollectionState) => {
        const todoState = state.filters.todoState;
        const todoAssignedTo = state.filters.todoAssignedTo;

        let query: any = firebaseService.getDb().collection("admin-tasks");

        if (todoAssignedTo == 0) {
          query = query.where("assignedToId", "==", user.value!.id);
        }

        if (todoState == 0) {
          query = query.where("done", "==", false);
        } else if (todoState == 1) {
          query = query.where("done", "==", true);
        }

        query = query.orderBy(state.sortBy, state.sortDesc ? "desc" : "asc");

        return query;
      },
      converter: new TodoConverter(),
      itemHandler: undefined,
    });
    const collectionOptions = {
      title: null,
      collection: "tasks",
      hideSearch: true,
      headers: [
        {
          text: i18n.t("tasks.createdon"),
          value: "createdon",
          sortable: false,
          customSlot: "item.createdon",
          customGetter: (item: any) => {
            return moment(item.createdon).format("DD.MM.YYYY HH:mm");
          },
        },
        { text: i18n.t("tasks.description"), value: "description", sortable: false, },
        {
          text: i18n.t("tasks.priority"),
          value: "priority",
          sortable: false,
          customSlot: "item.priority",
          customGetter: (item: any) => {
            return item.priority;
          },
          customClass: (item: any) => {
            return item.priority == 1
              ? "secondary--text"
              : item.priority == 2
              ? "warning--text"
              : "error--text";
          },
        },
        { text: i18n.t("tasks.assignedTo"), value: "assignedTo", sortable: false },
        {
          text: i18n.t("tasks.finishedon"),
          value: "finishedon",
          sortable: false,
          customSlot: "item.finishedon",
          customGetter: (item: any) => {
            return item.finishedon
              ? moment(item.finishedon).format("DD.MM.YYYY HH:mm")
              : null;
          },
          customClass: (item: any) => {
            return item.finishedon ? "success--text" : "";
          },
        },
      ],
      itemsActions: [
        {
          icon: "far fa-edit",
          color: "secondary",
          handler: editTodo,
        },
      ],
      collectionActions: [
        {
          icon: "fas fa-plus",
          color: "secondary",
          disabled: false,
          loading: false,
          tooltip: i18n.t("tasks.addNew"),
          handler: async () => {
            const newTodo = new Todo({
              id: uuid(),
              description: "",
              createdon: moment().toDate(),
              finishedon: null,
              done: false,
              assignedTo: null,
              assignedToId: null,
              businessId: null,
              priority: 1,
              note: null,
            });

            await editTodo(newTodo);
          },
        },
      ],
      collectionComposable,
    };

    return {
      collectionOptions,

      todoEditorDialog,
      todoEditable,
      currentTodo,
      updateTodo,

      todoState,
      todoAssignedTo,
    };
  },
});
