


























































import { computed, defineComponent, PropType, Ref, ref, watch } from "@vue/composition-api";
import { NewEditable } from "@/composables/new/types";
import moment from "moment";
export default defineComponent({
  props: {
    newEntry: Object as PropType<NewEditable>,
  },

  setup(props, { emit }) {
    watch(
      () => props.newEntry,
      () => {
        emit("modelChanged");
      },
      { deep: true }
    );

    const dateValue = ref(moment(props.newEntry!.date).toISOString(true).substr(0, 10));
    const dateModal = ref(false);
    const submitDateChange = () => {
      props.newEntry!.date = moment(dateValue.value).toDate();
      dateModal.value = false;
    };
    const dateText = computed(() => {
      return moment(dateValue.value).format("DD.MM.YYYY")
    });
    return {
      dateValue,
      dateText,
      dateModal,
      submitDateChange,      
    };
  },
});
