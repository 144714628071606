import firebaseService from "@/services/firebase";
import firebase from "firebase/app";
export class New {
    id!: string;    
    title!: string | null;
    description!: string;
    link!: string | null;
    date!: Date | firebase.firestore.Timestamp;

    constructor(data: Partial<New>) {
        Object.assign(this, data);
    }

    edit(): NewEditable {
        return new NewEditable(this);
    }
}


export class NewEditable {
    original: New;
    
    title: string | null;
    description: string;    
    link: string | null;
    date: Date;

    constructor(newEntry: New) {
        this.original = newEntry;
        
        this.title = newEntry.title;
        this.description = newEntry.description;
        this.link = newEntry.link;
        this.date = newEntry.date as Date;
    }

    async updateOriginal(): Promise<void> {
        this.original.title = this.title;
        this.original.description = this.description;
        this.original.link = this.link;
        this.original.date = this.date;

        await firebaseService.mergeNew(this.original.id, {
            title: this.title,
            description: this.description,
            link: this.link,
            date: firebase.firestore.Timestamp.fromDate(this.date as Date),
        })
    }
}