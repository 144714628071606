

















import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    message: {
      type: String,
    },
  },
});
