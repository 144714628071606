import { Addon, AddonConverter, Business, BusinessConverter } from '@/composables/business/types';
import { New } from '@/composables/new/types';
import { Todo } from '@/composables/todo/types';
import { AdminUser } from '@/composables/user/types';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

class FirebaseClient {
    private _app!: firebase.app.App;
    private _db!: firebase.firestore.Firestore;
    private _auth!: firebase.auth.Auth;

    constructor(config: any) {
        this.createApp(config);
    }

    private createApp(config: any) {
        this._app = firebase.initializeApp(config);

        if (process.env.NODE_ENV == 'development') {
            this._app.firestore().useEmulator("localhost", 8082);
            this._app.auth().useEmulator("http://localhost:9099");
            this._app.functions().useEmulator("127.0.0.1", 5001);
        }

        this._db = this._app.firestore();
        this._auth = this._app.auth();
    }

    async waitOnAuthentication(): Promise<firebase.User | null> {
        let unsubscribe: firebase.Unsubscribe | null = null;
        const user = await new Promise((res: (value: firebase.User | null) => void, rej) => {
            unsubscribe = this._auth.onAuthStateChanged((user: firebase.User | null) => {
                res(user);
            });
        })
        
        unsubscribe!();
        return user;
    }

    login(email: string, password: string): Promise<firebase.auth.UserCredential> {        
        return this._auth.signInWithEmailAndPassword(email, password);        
    }
  
    logout(): Promise<void> {
        return this._auth.signOut();
    }

    
    async getBusiness(id: string): Promise<Business> {
        const doc = await this._db.collection('businesses').doc(id).withConverter(new BusinessConverter()).get();
        if (false == doc.exists) {
            throw new Error('not-found');
        }
        return doc.data()!;
    }

    async getBusinessAddons(id: string): Promise<Addon[]> {
        const snap = await this._db.collection('addons').where('businessId', '==', id).withConverter(new AddonConverter()).get();
        return snap.docs.map(doc => doc.data());
    }

    async updateBusiness(business: Business): Promise<void> {
        const docRef = this._db.collection('businesses').doc(business.id).withConverter(new BusinessConverter());        
        await docRef.set(business, { merge: true })
    }

    async getAdminUsers(): Promise<AdminUser[]> {
        const snap = await this._db.collection('admin-users').get();
        return snap.docs.map(doc => {
            return {
                id: doc.id,
                name: doc.data().name
            }
        });
    }

    async business(method: string, payload: any): Promise<any> {
        try {
            const result = await this.callBackendFunction(`services-business-${method}`, payload);
            return result.data;
        } catch (error: any) {
            throw new Error(`business callable error: ${error.message}`);
        }
    }

    async admin(method: string, payload: any): Promise<any> {
        try {
            const result = await this.callBackendFunction(`services-admin-${method}`, payload, { timeout: 240000 });
            return result.data;
        } catch (error: any) {
            throw new Error(`admin callable error: ${error.message}`);
        }
    }

    async bonus(method: string, payload: any): Promise<any> {
        try {
            const result = await this.callBackendFunction(`services-bonus-${method}`, payload);
            return result.data;
        } catch (error: any) {
            throw new Error(`bonus callable error: ${error.message}`);
        }
    }


    async mergeTodo(id: string, todoPart: Partial<Todo>): Promise<void> {
        const docRef = this._db.collection('admin-tasks').doc(id);
        await docRef.set(todoPart, { merge: true });
    }

    async mergeNew(id: string, newPart: Partial<New>): Promise<void> {
        const docRef = this._db.collection('news').doc(id);
        await docRef.set(newPart, { merge: true });
    }

    async deleteNew(id: string): Promise<void> {
        const docRef = this._db.collection('news').doc(id);
        await docRef.delete();
    }

    async getShoptetInfoPages() {
        const decoder = new TextDecoder();
        const snap = await this._db.collection('pages-v2').get();
        const result: any = {}
        for(let i = 0; i < snap.size; i++) {
            const doc = snap.docs[i];
            const name = doc.id.replace('bonus_','');
            const lang = name.substring(0, 2);   
            const binString = atob(doc.data().content);
            const arr = Uint8Array.from(binString, c => c.charCodeAt(0));            
            result[lang] = decoder.decode(arr);
        }
        
        return result;
    }

    async saveShoptetInfoPages(pages: {[lang:string]:string}) {
        const encoder = new TextEncoder();
        const languages = Object.keys(pages);
        for(let i = 0; i< languages.length;i++) {
            const lang = languages[i];
            const bytes = encoder.encode(pages[lang]);
            const binString = String.fromCodePoint(...bytes);
            const record =btoa(binString)
            const id1 = `bonus_${lang}`;            
            await this._db.collection('pages-v2').doc(id1).set({content: record});
        }
    }

    private async callBackendFunction(functionName: string, payload: Record<string, unknown>, options?: firebase.functions.HttpsCallableOptions): Promise<firebase.functions.HttpsCallableResult> {
        let backendFunctionInvocation: firebase.functions.HttpsCallable;
        if(process.env.NODE_ENV == 'development') {
            backendFunctionInvocation = this._app.functions('http://127.0.0.1:5001/').httpsCallable(`mehub-cz/europe-central2/${functionName}`);
        } else {
            backendFunctionInvocation = this._app.functions('europe-central2').httpsCallable(functionName, options);
        }       
        const result = await backendFunctionInvocation(payload);
        return result;
    }

    public getDb(): firebase.firestore.Firestore {
        return this._db;
    }

    public getAuth(): firebase.auth.Auth {
        return this._auth;
    }
}

const instance = new FirebaseClient({
    apiKey: "AIzaSyDHGFFi0hiQ2_ezIR4uQIV5qVloaVivn74",
    authDomain: "mehub-cz.firebaseapp.com",
    projectId: "mehub-cz",
    storageBucket: "mehub-cz.appspot.com",
    messagingSenderId: "709222243032",
    appId: "1:709222243032:web:cbf84bb44cc5dd542695ad",
    measurementId: "G-ZXD314S6KF"
  });

export default instance;