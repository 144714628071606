var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.options.headers,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":1,"mustSort":"","loading":_vm.loading,"hide-default-footer":"","loading-text":_vm.$i18n.t('collectionViewer.loadingLabel'),"no-data-text":_vm.$i18n.t('collectionViewer.noDataText'),"header-props":{ sortIcon: 'fa-long-arrow-alt-up' }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-xl ellity-editor",attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.options.title))]),_c('v-spacer'),_c('v-spacer'),(!_vm.options.hideSearch)?_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchActive),expression:"searchActive"}],attrs:{"value":_vm.searchTerm,"dense":"","solo":"","flat":"","hide-details":"","label":_vm.options.searchTermLabel
            ? _vm.options.searchTermLabel
            : _vm.$i18n.t('collectionViewer.searchTermLabel')},on:{"change":_vm.searchInputChanged}}):_vm._e(),(!_vm.options.hideSearch)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading,"color":_vm.options.collectionActions.length > 0
            ? _vm.options.collectionActions[0].color
            : 'default'},on:{"click":_vm.toggleSearch}},[(!_vm.searchActive)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-search")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-times")])],1):_vm._e(),_vm._l((_vm.options.collectionActions),function(collectionAction,index){return _c('v-tooltip',{key:index + '-header-action',attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":collectionAction.disabled,"loading":collectionAction.loading || false,"color":collectionAction.color},on:{"click":collectionAction.handler}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(collectionAction.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(collectionAction.tooltip))])])})],2),_c('v-divider')]},proxy:true},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return _vm._l((_vm.options.itemsActions),function(itemAction,index){return _c('v-tooltip',{key:index + '-item-action',attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.loading,"color":itemAction.color},on:{"click":function($event){return itemAction.handler(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(itemAction.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(itemAction.title))])])})}},_vm._l((_vm.options.headers),function(header){return {key:header.customSlot,fn:function(ref){
            var item = ref.item;
return [_c('div',{key:header.D,class:header.customClass ? header.customClass(item) : ''},[_vm._v(" "+_vm._s(header.customGetter(item))+" ")])]}}})],null,true)},[_c('template',{slot:"footer"},[_c('v-divider'),_c('div',{staticClass:"text-right"},[_c('v-btn-toggle',{attrs:{"dense":"","group":""}},[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.canPrev},on:{"click":_vm.goToPrevious}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-arrow-left")])],1),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.canNext},on:{"click":_vm.goToNext}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-arrow-right")])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }