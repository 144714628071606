




















































































































































































































































































































































































































































































































































































import {
  defineComponent,
  ref,
  computed,
  Ref,
  watch,
} from "@vue/composition-api";
import useUser from "@/composables/user";
import firebaseService from "@/services/firebase";
import { BarChart, PieChart } from "vue-chart-3";
import DetailDialog from "@/components/DetailDialog.vue";
import moment from "moment";
import i18n from "@/plugins/i18n";
import utils from "../../services/utils";
import ReportItem from "@/components/ReportItem.vue";
import ReportItem2 from "@/components/ReportItem2.vue";
export default defineComponent({
  components: { BarChart, PieChart, DetailDialog, ReportItem, ReportItem2 },
  setup(props) {
    const { user } = useUser();

    const isLoadingTasks = ref(false);
    const lastTasks: Ref<any[]> = ref([]);

    const loadTasks = async () => {
      isLoadingTasks.value = true;
      let query = firebaseService
        .getDb()
        .collection("admin-tasks")
        .where("done", "==", false)
        .limit(3)
        .orderBy("createdon", "desc");
      const snap = await query.get();
      lastTasks.value = snap.docs.map((d) => ({
        description: d.data().description,
        createdon: moment(d.data().createdon.toDate()).format(
          "DD.MM.YYYY HH:mm"
        ),
      }));
      isLoadingTasks.value = false;
    };

    const isLoadingCurrentPayment = ref(false);
    const currentPaymentData: Ref<{
      items: {
        [currency: string]: number;
      };
      total: number;
    }> = ref({
      items: {},
      total: 0,
    });
    const loadCurrentPayment = async () => {
      try {
        isLoadingCurrentPayment.value = true;
        const result = await firebaseService.admin("getAdminReportingData", {
          reportType: "current-payment",
          parameters: {},
        });
        currentPaymentData.value = result;
        downloadPaymentData(result.businesses);
      } finally {
        isLoadingCurrentPayment.value = false;
      }
    };

    const downloadPaymentData = (busineses: any[]) => {
      let csv = "id;eshop;currency;total;amountType;invoicingType;payType;\n";
      for (let i = 0; i < busineses.length; i++) {
        const data = busineses[i];
        csv += `${data.id};${data.eshop};${data.currency};${data.total};${data.amountType};${data.invoicingType};${data.payType};\n`;
      }
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
      );
      element.setAttribute("download", "ellity-payments.csv");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    const isAddonStatsLoading = ref(false);
    const addonsStatsChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: "#fff",
      scales: {
        x: {
          grid: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: false,
          },
          ticks: {
            stepSize: 10,
          },
        },
      },
      locale: "cs",
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
    };
    const addonsStatsChartData = {
      labels: [
        "old",
        "trial",
        "zero",
        "free",
        "t500",
        "t1500",
        "t3500",
        "enterprise",
        "blocked",
      ],
      datasets: [
        {
          label: "count",
          data: [],
          backgroundColor: "#9CCC65",
        },
      ],
    };
    const newAccountsInTimeChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: "#fff",
      scales: {
        x: {
          grid: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: false,
          },
          ticks: {
            stepSize: 10,
          },
          max: 100,
        },
      },
      locale: "cs",
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
    };

    const newAccountsInDayChartData: any = {
      labels: [],
      datasets: [
        {
          label: "count",
          data: [],
          backgroundColor: "#9CCC65",
        },
      ],
    };
    const newAccountsInMonthChartData: any = {
      labels: [],
      datasets: [
        {
          label: "count",
          data: [],
          backgroundColor: "#9CCC65",
        },
      ],
    };
    const newAccountsInTimeChartData: Ref<any> = ref({
      labels: [],
      datasets: [],
    });

    const newAccountsInTimePeriods = [
      {
        text: "Den",
        value: "day",
      },
      {
        text: "Měsíc",
        value: "month",
      },
    ];
    const newAccountsInTimePeriod = ref(newAccountsInTimePeriods[1]);
    const newAccountsInTimePeriodChanged = (period: any) => {
      newAccountsInTimePeriod.value = period;
      if (period.value == "day") {
        newAccountsInTimeChartData.value.labels =
          newAccountsInDayChartData.labels;
        newAccountsInTimeChartData.value.datasets =
          newAccountsInDayChartData.datasets;
      } else {
        newAccountsInTimeChartData.value.labels =
          newAccountsInMonthChartData.labels;
        newAccountsInTimeChartData.value.datasets =
          newAccountsInMonthChartData.datasets;
      }
    };

    const addonsStats: Ref<any> = ref(null);
    const loadAddonStats = async () => {
      try {
        isAddonStatsLoading.value = true;
        const result = await firebaseService.admin("getAdminReportingData", {
          reportType: "addon-stats",
          parameters: {},
        });
        addonsStats.value = result;
        addonsStatsChartData.datasets[0].data = [
          result.free_zero,
          result.free_trial,
          result.old,
          result.free_zero_actived || 0,
          result.free_paid,
          result.t500,
          result.t1500,
          result.t3500,
          result.enterprise,
          result.blocked,
        ] as never[];
        addonsStatsChartData.labels = [
          `Neaktivni neplatici (${result.free_zero})`,
          `Trial (${result.free_trial})`,
          `Paid (${result.old})`,
          `Free-zero (akt) (${result.free_zero_actived || 0})`,
          `Free (${result.free_paid})`,
          `T500 (${result.t500})`,
          `T1500 (${result.t1500})`,
          `T3500 (${result.t3500})`,
          `Enterprise (${result.enterprise})`,
          `blokovani (${result.blocked})`,
        ];

        let sortedDates = Object.keys(result.newAccountsInTime.day).sort();
        let listX = [];
        let listY = [];
        for (let i = 0; i < sortedDates.length; i++) {
          const x = sortedDates[i];
          const y = result.newAccountsInTime.day[x];
          listX.push(x);
          listY.push(y);
        }
        newAccountsInDayChartData.labels = listX;
        newAccountsInDayChartData.datasets[0].data = listY;

        sortedDates = Object.keys(result.newAccountsInTime.month).sort();
        listX = [];
        listY = [];
        for (let i = 0; i < sortedDates.length; i++) {
          const x = sortedDates[i];
          const y = result.newAccountsInTime.month[x];
          listX.push(x);
          listY.push(y);
        }
        newAccountsInMonthChartData.labels = listX;
        newAccountsInMonthChartData.datasets[0].data = listY;

        newAccountsInTimeChartData.value.labels =
          newAccountsInMonthChartData.labels;
        newAccountsInTimeChartData.value.datasets =
          newAccountsInMonthChartData.datasets;
      } finally {
        isAddonStatsLoading.value = false;
      }
    };

    const getDates = (): {
      fromDate: string;
      toDate: string;
    } => {
      let fromDate = moment().format("YYYYMMDD");
      let toDate = moment()
        .add(1, "days")
        .format("YYYYMMDD");
      if (timePeriod.value == "week") {
        fromDate = moment()
          .startOf("isoWeek")
          .format("YYYYMMDD");
        toDate = moment()
          .endOf("isoWeek")
          .add(1, "days")
          .format("YYYYMMDD");
      } else if (timePeriod.value == "month") {
        fromDate = moment()
          .startOf("month")
          .format("YYYYMMDD");
        toDate = moment()
          .endOf("month")
          .add(1, "days")
          .format("YYYYMMDD");
      } else if (timePeriod.value == "year") {
        fromDate = moment()
          .startOf("year")
          .format("YYYYMMDD");
        toDate = moment()
          .endOf("year")
          .add(1, "days")
          .format("YYYYMMDD");
      } else if (timePeriod.value.indexOf("last") != -1) {
        let momentUnit = timePeriod.value.substring(4);
        if (momentUnit == "Week") {
          momentUnit = "isoWeek";
        }
        const initDate = moment().subtract(
          1,
          momentUnit as moment.unitOfTime.DurationConstructor
        );
        fromDate = initDate
          .startOf(momentUnit as moment.unitOfTime.StartOf)
          .format("YYYYMMDD");
        toDate = initDate
          .endOf(momentUnit as moment.unitOfTime.StartOf)
          .add(1, "days")
          .format("YYYYMMDD");
      }

      return {
        fromDate,
        toDate,
      };
    };

    const isLoadingRechargesAndDischarges = ref(false);
    const rechargesAndDischargesChartData = ref({
      labels: [],
      datasets: [],
    });
    const rechargesAndDischargesSumData = ref({
      recharges: {
        total: 0,
        change: 0,
      },
      discharges: {
        total: 0,
        change: 0,
      },
    });
    const rechargesAndDischargesChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: "#fff",
      scales: {
        x: {
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: i18n.t("home.rechargesAndDischargesXTitle"),
            align: "end",
          },
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: i18n.t("home.rechargesAndDischargesYTitle"),
            align: "end",
          },
        },
      },
      locale: "cs",
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
    };
    const rechargesAndDischargesChartPlugins = [
      {
        id: "custom_canvas_background_color",
        beforeDraw: (chart: any) => {
          const ctx = chart.canvas.getContext("2d");
          ctx.save();
          ctx.globalCompositeOperation = "destination-over";
          ctx.fillStyle = "white";
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    ];
    const loadRechargesAndDischarges = async (
      fromDate: string,
      toDate: string
    ) => {
      try {
        isLoadingRechargesAndDischarges.value = true;
        const result = await firebaseService.admin("getAdminReportingData", {
          reportType: "bonus-recharges-discharges",
          parameters: {
            fromDate,
            toDate,
            currency: revenueCurrency.value,
          },
        });
        rechargesAndDischargesChartData.value = {
          labels: result.timePoints,
          datasets: [
            {
              label: i18n.t("home.dischargeLegend").toString(),
              data: result.discharges.series,
              backgroundColor: "#EF5350",
            } as never,
            {
              label: i18n.t("home.rechargeLegend").toString(),
              data: result.recharges.series,
              backgroundColor: "#9CCC65",
            } as never,
          ],
        };
        rechargesAndDischargesSumData.value = {
          recharges: {
            total: result.recharges.total.toLocaleString("cs"),
            change: result.recharges.change,
          },
          discharges: {
            total: result.discharges.total.toLocaleString("cs"),
            change: result.discharges.change,
          },
        };
      } finally {
        isLoadingRechargesAndDischarges.value = false;
      }
    };

    const isLoadingRevenues = ref(false);
    const revenuesChartData = ref({
      labels: [],
      datasets: [],
    });
    const revenuesSumData = ref({
      total: 0,
      withCredit: 0,
      percently: 0,
    });
    const revenueCurrency: Ref<string | null> = ref("CZK");
    watch(
      () => revenueCurrency.value,
      () => {
        if (timePeriod.value != "range") {
          const { fromDate, toDate } = getDates();
          loadRevenues(fromDate, toDate);
        }
      }
    );
    const revenuesChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: "#fff",
      layout: {
        padding: 25,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };
    const loadRevenues = async (fromDate: string, toDate: string) => {
      try {
        isLoadingRevenues.value = true;
        if (revenueCurrency.value) {
          const result = await firebaseService.admin("getAdminReportingData", {
            reportType: "bonus-revenues",
            parameters: {
              currency: revenueCurrency.value,
              fromDate,
              toDate,
            },
          });
          revenuesChartData.value = {
            labels: [
              `${i18n.t("home.revenuesTotalTitle").toString()} ${
                result.total
              },-` as never,
              `${i18n.t("home.revenuesWithCreditTitle").toString()}${
                result.withCredit
              },- ` as never,
            ],
            datasets:
              result.total == 0 && result.withCredit == 0
                ? [
                    {
                      data: [1, 1],
                      backgroundColor: [
                        "rgba(0, 0, 0, 0.05)",
                        "rgba(0, 0, 0, 0.02)",
                      ],
                      hoverOffset: 4,
                    } as never,
                  ]
                : [
                    {
                      data: [result.total, result.withCredit],
                      backgroundColor: ["#78909C", "#EF5350"],
                      hoverOffset: 4,
                    } as never,
                  ],
          };
          revenuesSumData.value = {
            percently: result.percently,
            total: result.total,
            withCredit: result.withCredit,
          };
        }
      } finally {
        isLoadingRevenues.value = false;
      }
    };

    const isLoadingPurchases = ref(false);
    const purchasesData = ref({
      eshop: {
        id: "",
        shoptetId: "",
        url: "",
      },
      netLoyaltyScore: {
        current: 0,
        previous: 0,
        change: 0,
      },
      total: {
        current: 0,
        previous: 0,
        change: 0,
      },
      asp: {
        current: 0,
        previous: 0,
        change: 0,
      },
      revenue: {
        current: 0,
        previous: 0,
        change: 0,
      },
      onePurchase: {
        count: {
          current: 0,
          previous: 0,
          change: 0,
          partOfTotal: 0,
        },
        revenue: {
          current: 0,
          previous: 0,
          change: 0,
          partOfTotal: 0,
        },
      },
      twoPurchases: {
        count: {
          current: 0,
          previous: 0,
          change: 0,
          partOfTotal: 0,
        },
        revenue: {
          current: 0,
          previous: 0,
          change: 0,
          partOfTotal: 0,
        },
      },
      threePurchases: {
        count: {
          current: 0,
          previous: 0,
          change: 0,
          partOfTotal: 0,
        },
        revenue: {
          current: 0,
          previous: 0,
          change: 0,
          partOfTotal: 0,
        },
      },
      fourAndMorePurchases: {
        count: {
          current: 0,
          previous: 0,
          change: 0,
          partOfTotal: 0,
        },
        revenue: {
          current: 0,
          previous: 0,
          change: 0,
          partOfTotal: 0,
        },
      },
      customers: {
        current: 0,
        previous: 0,
        change: 0,
      },
    });

    const exportPurchasesToCsv = async () => {
      try {
        isLoadingPurchases.value = true;

        if (revenueCurrency.value) {
          const { fromDate, toDate } = getDates();
          const result = await firebaseService.admin("getAdminReportingData", {
            reportType: "purchases",
            parameters: {
              currency: revenueCurrency.value,
              fromDate,
              toDate,
            },
          });

          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(result.csv)
          );
          element.setAttribute("download", "ellity-purchases.csv");
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
      } finally {
        isLoadingPurchases.value = false;
      }
    };

    const refresh = async () => {
      if (timePeriod.value != "range") {
        const { fromDate, toDate } = getDates();
        loadRechargesAndDischarges(fromDate, toDate);
        loadRevenues(fromDate, toDate);
      }
    };

    const timePeriod = ref("day");
    watch(
      () => timePeriod.value,
      () => {
        if (timePeriod.value != "range") {
          const { fromDate, toDate } = getDates();
          loadRechargesAndDischarges(fromDate, toDate);
          loadRevenues(fromDate, toDate);
        }
      }
    );

    const currentPeriodText = computed(() => {
      if (timePeriod.value == "range") {
        return i18n.t("home.timePeriodRangeText");
      } else if (timePeriod.value == "day") {
        return i18n.t("home.timePeriodDayText");
      } else if (timePeriod.value == "week") {
        return i18n.t("home.timePeriodWeekText");
      } else if (timePeriod.value == "month") {
        return i18n.t("home.timePeriodMonthText");
      } else if (timePeriod.value == "year") {
        return i18n.t("home.timePeriodYearText");
      } else if (timePeriod.value.indexOf("last") != -1) {
        return i18n.t(`home.timePeriod${timePeriod.value}Text`);
      }
    });

    const lastPeriodText = computed(() => {
      if (timePeriod.value == "day") {
        return i18n.t("home.lastDay");
      } else if (timePeriod.value == "week") {
        return i18n.t("home.lastWeek");
      } else if (timePeriod.value == "month") {
        return i18n.t("home.lastMonth");
      } else if (timePeriod.value == "year") {
        return i18n.t("home.lastYear");
      } else if (
        timePeriod.value.indexOf("last") != -1 ||
        timePeriod.value == "range"
      ) {
        return i18n.t(`home.lastRange`);
      }
    });

    const dateRange = ref([moment().toISOString(), moment().toISOString()]);
    const dateRangeModal = ref(false);
    const dateRangeText = computed(() => {
      return dateRange.value
        .map((d) => moment(d).format("DD.MM.YYYY"))
        .join(" - ");
    });
    const submitDateRange = () => {
      const fromDate = moment(dateRange.value[0]).format("YYYYMMDD");
      const toDate = moment(dateRange.value[1])
        .add("1", "days")
        .format("YYYYMMDD");
      loadRechargesAndDischarges(fromDate, toDate);
      loadRevenues(fromDate, toDate);
      dateRangeModal.value = false;
    };

    const datePickerLocale = computed(() => {
      return "cs-CZ";
    });

    const initFromDate = moment().format("YYYYMMDD");
    const initToDate = moment()
      .add(1, "days")
      .format("YYYYMMDD");
    loadRechargesAndDischarges(initFromDate, initToDate);
    loadRevenues(initFromDate, initToDate);

    const newxInvoicingDate = moment()
      .endOf("month")
      .add(1, "days")
      .format("DD.MM.YYYY");

    loadAddonStats();

    loadTasks();

    return {
      currencies: ["CZK", "EUR", "HUF"],
      isLoadingCurrentPayment,
      currentPaymentData,

      isAddonStatsLoading,
      addonsStatsChartOptions,
      addonsStatsChartData,
      newAccountsInTimeChartOptions,
      newAccountsInDayChartData,
      newAccountsInMonthChartData,
      addonsStats,

      user,

      rechargesAndDischargesChartData,
      rechargesAndDischargesSumData,
      rechargesAndDischargesChartOptions,
      rechargesAndDischargesChartPlugins,
      isLoadingRechargesAndDischarges,

      revenuesChartData,
      revenuesSumData,
      revenueCurrency,
      revenuesChartOptions,
      isLoadingRevenues,

      purchasesData,
      isLoadingPurchases,

      timePeriod,
      dateRange,
      dateRangeModal,
      dateRangeText,
      submitDateRange,

      datePickerLocale,
      currentPeriodText,
      lastPeriodText,

      loadCurrentPayment,
      refresh,

      newxInvoicingDate,

      lastTasks,
      isLoadingTasks,

      newAccountsInTimePeriod,
      newAccountsInTimePeriods,
      newAccountsInTimePeriodChanged,
      newAccountsInTimeChartData,

      utils,
      language: "cs",

      exportPurchasesToCsv,
    };
  },
});
