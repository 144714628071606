import Vue from 'vue';
import VueI18n from 'vue-i18n'
import en from '@/lang/en'
import cs from '@/lang/cs'
Vue.use(VueI18n);

export default new VueI18n({
    locale: "cs",
    fallbackLocale: 'cs',
    messages: {en,cs}
});
