var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"value":_vm.show,"persistent":"","transition":"dialog-bottom-transition","content-class":'detail-dialog ' + (_vm.nested || _vm.nestedOnlyUi ? 'nested' : '')}},[(_vm.saving)?_c('v-card',[_c('v-container',{staticClass:"dialog-container pa-0",attrs:{"fill-height":""}},[_c('Progress',{attrs:{"message":_vm.progressMessage
              ? _vm.progressMessage
              : _vm.$i18n.t('detailDialog.progressMessage')}})],1)],1):_vm._e(),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(false == _vm.saving),expression:"false == saving"}]},[_c('v-toolbar',{staticClass:"dialog-toolbar",attrs:{"dense":""}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.saving},on:{"click":_vm.cancelChanges}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.nested ? "fa-arrow-left" : "fa-times")+" ")])],1),_c('v-toolbar-title',{staticClass:"dialog-title"},[_vm._v(_vm._s(this.title))]),_c('v-spacer'),(_vm.hasSubactions)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-ellipsis-v")])],1)]}}],null,false,3278617483)},[_c('v-list',[_vm._t("actions",null,{"processActionWithBusy":_vm.processActionWithBusy})],2)],1):_vm._e()],1),(_vm.actionProcessing)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-alert',{staticClass:"ma-0 rounded-0",attrs:{"dense":"","dismissible":"","type":"error"},model:{value:(_vm.showValidationMessage),callback:function ($$v) {_vm.showValidationMessage=$$v},expression:"showValidationMessage"}},[_vm._v(" "+_vm._s(_vm.validationMessage)+" ")]),_c('v-container',{staticClass:"dialog-container pa-5 pt-0"},[_c('v-form',{ref:"detailForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card',{staticClass:"rounded-xl fill-height pa-3"},[_c('v-card-text',{staticClass:"pb-2"},[_vm._t("content",null,{"onCustomValidationChanged":_vm.onCustomValidationChanged,"onModelChanged":_vm.onModelChanged})],2),(_vm.saveHandler)?_c('v-card-actions',{staticClass:"d-flex flex-column pt-0"},[_c('v-btn',{staticClass:"pl-5 pr-5 mt-7 mb-5",attrs:{"color":"success","rounded":"","disabled":_vm.saving},on:{"click":_vm.saveChanges}},[(false == _vm.btnIconHide)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.nested ? "fa-check" : "fa-save")+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.customBtnText ? _vm.customBtnText : _vm.nested ? _vm.$i18n.t("detailDialog.confirm") : _vm.$i18n.t("detailDialog.save"))+" ")],1)],1):_vm._e()],1)],1)],1)],1)],1),_c('ConfirmationDialog',{attrs:{"title":_vm.nested
        ? _vm.$i18n.t('detailDialog.unconfirmedChanges')
        : _vm.$i18n.t('detailDialog.unsavedChanges'),"backButton":_vm.$i18n.t('detailDialog.back'),"confirmButton":_vm.$i18n.t('detailDialog.close')},on:{"confirm":_vm.innerCancelChanges},model:{value:(_vm.showCancelConfirmationDialog),callback:function ($$v) {_vm.showCancelConfirmationDialog=$$v},expression:"showCancelConfirmationDialog"}},[_c('p',{staticClass:"font-weight-regular py-2 my-0"},[_vm._v(" "+_vm._s(_vm.nested ? _vm.$i18n.t("detailDialog.changesWillNotBeConfirmed") : _vm.$i18n.t("detailDialog.changesWillNotBeSaved"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }