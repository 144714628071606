




















































































































import Vue from "vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import Progress from "./Progress.vue";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import i18n from "@/plugins/i18n";

export default defineComponent({
  components: {
    ConfirmationDialog,
    Progress,
  },

  model: {
    prop: "show",
    event: "showChange",
  },

  props: {
    title: String,
    nested: {
      type: Boolean,
      default: false,
    },
    nestedOnlyUi: {
      type: Boolean,
      default: false,
    },
    show: Boolean,
    saveHandler: Function,
    closeHandler: Function,
    btnIconHide: {
      type: Boolean,
      default: false,
    },
    customBtnText: {
      type: String,
      default: null,
    },
    progressMessage: {
      type: String,
      default: null,
    },
    hasSubactions: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit, refs }) {
    const formValid = ref(true);
    const showValidationMessage = ref(false);
    const validationMessage = ref(null as string | null);
    const showCancelConfirmationDialog = ref(false);
    const dirty = ref(false);
    const saving = ref(false);
    const customValidationResult = ref(true);

    watch(
      () => props.show,
      (_, oldValue) => {
        const opening = !oldValue;
        if (opening) {
          dirty.value = false;
          formValid.value = true;
          showValidationMessage.value = false;
          validationMessage.value = null;
          const form = refs.detailForm as Vue & {
            validate: () => boolean;
            resetValidation: () => void;
          };
          if (form) {
            form.resetValidation();
          }
        }
      },
      { immediate: true }
    );

    const saveChanges = async () => {
      try {
        saving.value = true;
        const form = refs.detailForm as Vue & {
          validate: () => boolean;
          resetValidation: () => void;
        };
        const isValid = customValidationResult.value && form.validate();
        if (isValid) {
          await props.saveHandler!();
          closeDialog();
        } else {
          validationMessage.value = props.nested
            ? i18n.t("detailDialog.repairInputAndConfirmAgain").toString()
            : i18n.t("detailDialog.repairInputAndSaveAgain").toString();
          showValidationMessage.value = true;
        }
      } catch (error) {
        validationMessage.value = i18n
          .t("detailDialog.errorMessage", { msg: error.message })
          .toString();
        showValidationMessage.value = true;
      } finally {
        saving.value = false;
      }
    };

    const cancelChanges = () => {
      if (dirty.value) {
        showCancelConfirmationDialog.value = true;
      } else {
        innerCancelChanges();
      }
    };

    const innerCancelChanges = () => {
      closeDialog();
    };

    const closeDialog = () => {
      emit("showChange", false);
      dirty.value = false;

      if (props.closeHandler) {
        props.closeHandler();
      }
    };

    const preventDirtyNav = (event: any) => {
      if (!dirty.value) {
        return;
      }

      event.preventDefault();
      event.returnValue = "";
    };

    const onCustomValidationChanged = (_customValidationResult: boolean) => {
      customValidationResult.value = _customValidationResult;
    };

    const onModelChanged = () => {
      dirty.value = true;
    };

    const actionProcessing = ref(false);    

    return {
      formValid,
      showValidationMessage,
      validationMessage,
      showCancelConfirmationDialog,
      dirty,
      saving,

      actionProcessing,
      processActionWithBusy: async (actionHandler: () => Promise<void>) => {
          try {
          actionProcessing.value = true;
          await actionHandler();
        } finally {
          actionProcessing.value = false;
        }
      },

      saveChanges,
      cancelChanges,
      innerCancelChanges,
      preventDirtyNav,

      closeDialog,

      onCustomValidationChanged,
      onModelChanged,      
    };
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventDirtyNav);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventDirtyNav);
  },
});
