




































































import {
  defineComponent,
  PropType,
  watch,
  ref,
  Ref,
} from "@vue/composition-api";
import { TodoEditable } from "@/composables/todo/types";
import moment from "moment";
import DetailDialog from "@/components/DetailDialog.vue";
import BusinessEditor from "@/components/BusinessEditor.vue";
import firebaseService from "@/services/firebase";
import i18n from "@/plugins/i18n";
export default defineComponent({
  props: {
    todo: Object as PropType<TodoEditable>,
  },
  components: {
    DetailDialog,
    BusinessEditor,
  },
  setup(props, { emit }) {
    watch(
      () => props.todo,
      () => {
        if (false == props.todo!.done) {
          props.todo!.finishedon = null;
        } else if (null == props.todo!.finishedon) {
          props.todo!.finishedon = moment().toDate();
        }
        emit("modelChanged");
      },
      { deep: true }
    );

    const isAdminUsersLoading = ref(true);
    const adminUsers: Ref<{ text: string; value: string | null }[]> = ref([]);
    const loadAdminUsers = async () => {
      const users = await firebaseService.getAdminUsers();
      const arr: { text: string; value: string | null }[] = [];
      arr.push({
        text: i18n.t("tasks.someone").toString(),
        value: null,
      });
      for (let i = 0; i < users.length; i++) {
        arr.push({
          text: users[i].name,
          value: users[i].id,
        });
      }
      adminUsers.value = arr;
      isAdminUsersLoading.value = false;
    };
    loadAdminUsers();

    const changeAssignedTo = (adminUser: {
      text: string;
      value: string | null;
    }) => {
      props.todo!.assignedTo = adminUser.text;
      props.todo!.assignedToId = adminUser.value;
    };

    return {

      isAdminUsersLoading,
      adminUsers,
      changeAssignedTo,
    };
  },
});
