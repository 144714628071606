








































import { defineComponent } from "@vue/composition-api";
import i18n from "@/plugins/i18n";
import useUser from "@/composables/user";
export default defineComponent({
  setup(props, context) {
    const { logout, user } = useUser();
    const router = context.root.$router;
    const items = [
      {
        title: i18n.t("navigation.home"),
        icon: "fas fa-home",
        to: {
          name: "home",
        },
      },
      {
        title: i18n.t("navigation.businesses"),
        icon: "fas fa-users",
        to: {
          name: "businesses",
          params: {
            initBusinessState: 3,
          },
        },
      },
    ];

    if (user.value?.role != "dev") {
      items.push(
        ...[
          {
            title: i18n.t("navigation.tasks"),
            icon: "fas fa-tasks",
            to: {
              name: "tasks",
            },
          },
          {
            title: i18n.t("navigation.shoptet"),
            icon: "fas fa-edit",
            to: {
              name: "shoptet",
            },
          },
          {
            title: i18n.t("navigation.news"),
            icon: "fas fa-star",
            to: {
              name: "news",
            },
          },
        ]
      );
    }

    items.push({
      title: i18n.t("navigation.map"),
      icon: "fas fa-map",
      to: {
        name: "map",
      },
    });

    const signout = async () => {
      await logout();
      router.push({
        name: "login",
        params: { returnUrl: router.currentRoute.fullPath },
      });
    };
    return {
      items,
      signout,
    };
  },
});
