





























































































































import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { CollectionComposable } from "@/composables/collection";

export default defineComponent({
  props: {
    options: {
      type: Object as PropType<{
        title: string;
        collection: string;
        headers: any[];
        itemsActions: {
          handler: () => Promise<void>;
          icon: string;
        }[];
        collectionActions: {
          handler: () => Promise<void>;
          icon: string;
        }[];
        hideSearch: boolean | undefined;
        searchTermLabel: string | undefined;
        collectionComposable: CollectionComposable;
      }>,
      required: true,
    },
  },
  setup(props) {
    const itemsPerPage = ref(1);
    const handleResize = () => {
      const rowHeightApproxPlusReserve = 50;
      const otherHeights = 4 * 48; // nav + table header + table footer + paddings
      itemsPerPage.value = Math.floor(
        2 * ((window.innerHeight - otherHeights) / rowHeightApproxPlusReserve)
      );
    };

    const searchTerm: Ref<string | null> = ref(null);
    const searchActive = ref(false);
    const searchInputChanged = (input: string) => {
      searchTerm.value = input;
    };
    const toggleSearch = () => {
      searchTerm.value = null;
      searchActive.value = !searchActive.value;
    };

    watch([() => searchTerm.value], async () => {
      await props.options.collectionComposable.fetch({
        itemsPerPage: undefined,
        filters: {
          ...props.options.collectionComposable.filters.value,
          searchTerm: searchTerm.value,
        },
      });
    });

    const sortBy = ref(props.options.collectionComposable.sortBy);
    const sortDesc = ref(props.options.collectionComposable.sortDesc || false);
    watch([() => sortBy.value, () => sortDesc.value], async () => {
      await props.options.collectionComposable.orderBy(
        sortBy.value,
        sortDesc.value
      );
    });

    watch(
      [() => itemsPerPage.value],
      async () => {
        await props.options.collectionComposable.fetch({
          itemsPerPage: itemsPerPage.value,
          filters: undefined,
        });
      },
      { immediate: true }
    );

    const headers = props.options.headers;
    if (props.options.itemsActions.length > 0) {
      headers.push({
        text: "",
        value: "actions",
        filterable: false,
        sortable: false,
        align: "end",
      });
    }

    return {
      handleResize,

      tableHeaders: headers,

      searchTerm,
      searchActive,
      searchInputChanged,
      toggleSearch,

      sortBy,
      sortDesc,

      goToPrevious: props.options.collectionComposable.goToPrevious,
      goToNext: props.options.collectionComposable.goToNext,
      loading: props.options.collectionComposable.loading,
      items: props.options.collectionComposable.items,
      canPrev: props.options.collectionComposable.canPrev,
      canNext: props.options.collectionComposable.canNext,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
});
