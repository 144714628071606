
















import { defineComponent, watch, ref } from "@vue/composition-api";
import Navigation from "@/components/Navigation.vue";
import useUser from "@/composables/user";

export default defineComponent({
  components: {
    Navigation
  },
  setup(props, context) {
    const { initAuthentication, isAuthenticated } = useUser();
    const router = context.root.$router;
    const authLoading = ref(true);

    initAuthentication().then(() => {
      authLoading.value = false;
      if (isAuthenticated.value) {
          if (router.currentRoute.name == "login") {
            router.push({ name: "home" });
          }
        } else if (router.currentRoute.name != "login") {
          router.push({ name: "login", params: { returnUrl: router.currentRoute.fullPath } });
        }
    })   

    return {
      authLoading,
      isAuthenticated
    };
  },
});
