



















































import { defineComponent, Ref, ref, watch, } from "@vue/composition-api";
import firebaseService from "../../services/firebase";
export default defineComponent({
    setup(props) {
        const languages: Ref<string[]> = ref([]);
        const language = ref(0);
        const texts: Ref<string[]> = ref([]);
        const textChanged = (v: string) => {
            texts.value[language.value] = v;
            reviewText.value = v;
        }
        const reviewText = ref('');
        const save = async () => {
            await firebaseService.saveShoptetInfoPages(texts.value.reduce((acc, item, idx) => {
                return {
                    ...acc,
                    [languages.value[idx]]: item
                }
            }, {}))
        }

        firebaseService.getShoptetInfoPages().then((pages) => {
            for (let lang in pages) {
                languages.value.push(lang);
                texts.value.push(pages[lang]);
            }
            reviewText.value = texts.value[language.value];
        });

        watch(language, () => {
            reviewText.value = texts.value[language.value];
        })
        
        return {
            languages,
            language,
            texts,
            textChanged,
            save,
            reviewText
        };
    },
});
