
import firebase from "firebase/app";
import moment from "moment";

export interface Address {
    streetAddress: string;
    city: string;
    zip: string;
    country: string;
  }
  export interface Billing {
    billingName: string;
    subId: string;
    taxId: string;
    vatId: string | null; // not use
    address: Address;
    billingEmail: string;
    currency: string;
    stripe: StripeMetadata | null;
    fakturoid: FakturoidMetadata | null;
    invoicingType: InvoicingType;
    amountType: AmountType;
    payType: PayType;
    fixAmount: number | null;
    credit: number;
    prepayMetadata: PrepayMetadata | null;
    blockedByPayment: boolean;
    activatedAt: Date | null;   
    monthlyDiscount: number | null;
  }
  
  export interface StripeMetadata {
    customerId: string;
    couponId: string | null;
    paymentMethodId: string | null;
    taxRateId: string;
    setupId: string | null;
  }
  
  export interface FakturoidMetadata {
    subjectId: string;
  }
  
  export interface PrepayMetadata {
    numberOfMonths: number;
    hasAnnualPrices: boolean;
    firstDayOfNextPaymentMonth: Date;
    licencePrice: number;
  }
  
  export enum InvoicingType {
    back = "back",
    prepay = "prepay",
  }
  
  export enum AmountType {
    plan = "plan",
    fix = "fix",
  }
  
  export enum PayType {
    card = "card",
    transfer = "transfer",
  }
  
  export interface Contact {
    email: string;
    phone: string;
  }
  
  export interface Business {
    id: string;
    eshopId: string | null;
    eshopUrl: string | null;
    eshopName: string | null;
    oAuthUrl: string | null;
    language: string;
    contact: Contact;
    billing: Billing | null;
    hideSetupGuide: boolean | null;
    metadata: any | null;
    addonState: AddonState | null;
    createdAt: Date | null;
  }
  
  export interface BusinessEditable {
   contact: {
    email: string;
    phone: string;
   },
    billing: {
        activatedAt: Date | null;
        stripe:{couponId: string | null} | null;
        invoicingType: InvoicingType;
        amountType: AmountType;
        payType: PayType;
        fixAmount: number | null;
        prepayMetadata: PrepayMetadata | null;
        blockedByPayment: boolean;
        monthlyDiscount: number | null;
    } | null,
    original: Business;
  }

  export class BusinessConverter implements firebase.firestore.FirestoreDataConverter<Business> {
    toFirestore(business: Business): firebase.firestore.DocumentData {
      return {
        language: business.language,
        eshopUrl: business.eshopUrl,
        billing: business.billing
          ? {
              billingName: business.billing.billingName,
              subId: business.billing.subId,
              taxId: business.billing.taxId,
              vatId: business.billing.vatId,
              billingEmail: business.billing.billingEmail,
              currency: business.billing.currency,
              address: {
                streetAddress: business.billing.address.streetAddress,
                city: business.billing.address.city,
                zip: business.billing.address.zip,
                country: business.billing.address.country,
              },
              stripe: business.billing.stripe
                ? {
                    customerId: business.billing.stripe.customerId,
                    couponId: business.billing.stripe.couponId,
                    paymentMethodId: business.billing.stripe.paymentMethodId,
                    taxRateId: business.billing.stripe.taxRateId,
                    setupId: business.billing.stripe.setupId,
                  }
                : null,
              fakturoid: business.billing.fakturoid
                ? {
                    subjectId: business.billing.fakturoid.subjectId,
                  }
                : null,
              invoicingType: business.billing.invoicingType,
              amountType: business.billing.amountType,
              payType: business.billing.payType,
              fixAmount: business.billing.fixAmount,
              credit: business.billing.credit,
              prepayMetadata: business.billing.prepayMetadata
                ? {
                    firstDayOfNextPaymentMonth: firebase.firestore.Timestamp.fromDate(
                      business.billing.prepayMetadata.firstDayOfNextPaymentMonth
                    ),
                    hasAnnualPrices:
                      business.billing.prepayMetadata.hasAnnualPrices,
                    licencePrice: business.billing.prepayMetadata.licencePrice,
                    numberOfMonths:
                      business.billing.prepayMetadata.numberOfMonths,
                  }
                : null,
              blockedByPayment: business.billing.blockedByPayment,
              activatedAt: business.billing.activatedAt
                ? firebase.firestore.Timestamp.fromDate(business.billing.activatedAt)
                : null,
              createdAt: business.createdAt
                ? firebase.firestore.Timestamp.fromDate(business.createdAt)
                : null,
                monthlyDiscount: business.billing.monthlyDiscount,
            }
          : null,
        contact: {
          email: business.contact.email,
          phone: business.contact.phone,
        },
  
        hideSetupGuide: business.hideSetupGuide,
        metadata: business.metadata,
        addonState: business.addonState,
      };
    }
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      options: firebase.firestore.SnapshotOptions
    ): Business {
      const data = snapshot.data();
      return {
        id: snapshot.id,
        eshopId: data.eshopId,
        eshopUrl: data.eshopUrl,
        eshopName: data.eshopName,
        oAuthUrl: data.oAuthUrl,
        language: data.language,
        contact: {
          email: data.contact.email,
          phone: data.contact.phone,
        },
        billing: data.billing
          ? {
              billingName: data.billing.billingName || "",
              subId: data.billing.subId || "",
              taxId: data.billing.taxId || "",
              vatId: data.billing.vatId || "",
              billingEmail: data.billing.billingEmail,
              address: {
                streetAddress: data.billing.address.streetAddress || "",
                city: data.billing.address.city || "",
                zip: data.billing.address.zip || "",
                country: data.billing.address.country || "",
              },
              currency: data.billing.currency,
              stripe: data.billing.stripe
                ? {
                    customerId: data.billing.stripe.customerId,
                    couponId: data.billing.stripe.couponId,
                    paymentMethodId: data.billing.stripe.paymentMethodId,
                    taxRateId: data.billing.stripe.taxRateId,
                    setupId: data.billing.stripe.setupId,
                  }
                : null,
              fakturoid: data.billing.fakturoid
                ? {
                    subjectId: data.billing.fakturoid.subjectId,
                  }
                : null,
              invoicingType: data.billing.invoicingType,
              amountType: data.billing.amountType,
              payType: data.billing.payType,
              fixAmount: data.billing.fixAmount,
              credit: data.billing.credit,
              prepayMetadata: data.billing.prepayMetadata
                ? {
                    numberOfMonths: data.billing.prepayMetadata.numberOfMonths,
                    hasAnnualPrices: data.billing.prepayMetadata.hasAnnualPrices,
                    firstDayOfNextPaymentMonth:
                      data.billing.prepayMetadata.firstDayOfNextPaymentMonth &&
                      data.billing.prepayMetadata.firstDayOfNextPaymentMonth
                        .toDate
                        ? data.billing.prepayMetadata.firstDayOfNextPaymentMonth.toDate()
                        : data.billing.prepayMetadata
                            .firstDayOfNextPaymentMonth || null,
                    licencePrice: data.billing.prepayMetadata.licencePrice,
                  }
                : null,
              blockedByPayment: data.billing.blockedByPayment,
              activatedAt: data.billing.activatedAt
                ? data.billing.activatedAt.toDate()
                : null,
              monthlyDiscount: data.billing.monthlyDiscount || null,
            }
          : null,
        metadata: data.metadata || null,
        hideSetupGuide: data.hideSetupGuide,
        addonState: data.addonState || null,
        createdAt: data.createdAt && data.createdAt.toDate
          ? data.createdAt.toDate()
          : data.createdAt || null,
      };
    }
  }
  
  export enum AddonState {
    INSTALLED = "installed",
    UNINSTALLED = "uninstalled",
    SUSPENDED = "suspended",
    ACTIVE = "active",
    BLOCKED = "blocked",
  }
  
  enum AddonWarnings {
    PAYMENT_FAILED = "payment-failed",
    UNSUPPORTED_TEMPLATE = "unsupported-template",
  }
  
  export interface Addon {
    id: string;
    businessId: string;
    state: AddonState;
    warnings: AddonWarnings[];
    configured: boolean;
    logoUrl: string | null;
  
    initializationError: string | null;
    initializationFinishedon: Date | null;
  
    syncTaskId: string | null;
    lastSyncedon: string | null;
  
    settings: BaseSettings & CustomerGroupsSettings & InfoPageSettings &
    SmartmaillingSettings &
    LeadhubSettings &
    MaillingSettings;
  }
  
  export enum CreditComputationMode {
    WITH_VAT = "with-vat",
    WITHOUT_VAT = "without-vat",
  }
  
  export enum ShowOnFrontendMode {
    ALL = "all",
    ONLY_ADMIN = "admin",
  }
  
  export interface BonusAddonLicence {
    plan: Plan;
    features: Feature[];
    overlimits: { date: string; limit: number; over: number }[];
  }
  
  export enum Feature {
    LIMIT_DISCOUNT = "limit-discount",
    MANUAL_SYNC = "manual-sync",
    DELAYED_RECHARGE = "delayed-recharge",
    APPS_SMARTMAILLING = "apps-smartmailling",
    APPS_LEADHUB = "apps-leadhub",
    FRONTEND_VISIBILITY = "frontend-visibility",
    MINIMAL_TOTAL = "minimal-total",
    UNLIMITED_ORDERS = "unlimited-orders",
    EXPIRATION = "expiration",
    REGISTRATION = "registration",
    NEWSLETTER = "newsletter",
    PRODUCT_COEFFICIENT = "product-coefficient",
    BRAND_CATEGORY_COEFFICIENT = "brand-category-coefficient",
    GROUP_COEFFICIENT = "group-coefficient",
    ANONYMOUS = "anonymous",
    EXTENDED_ANALYTICS = "extended-analytics",
    EXTENDED_SUPPORT = "extended-support",
  }
  
  export enum Plan {
    PAID = "paid",
  FREE = "free",
  T500 = "t500",
  T1500 = "t1500",
  T3500 = "t3500",
  ENTERPRISE = "enterprise",
  }
  
  interface BaseSettings {
    // licence
    licence: BonusAddonLicence;
  
    // for init subcollections
    currencies: string[];
  
    // default discharge price coefficient - price in currency per one credit
    discharge: {
      [currency: string]: number;
    };
  
    // coefficient for paid order - price in currency per one credit
    actionPayOrder: {
      [currency: string]: number;
    };
  
    // minimal order total for discharging
    dischargeMinimalTotal: {
      [currency: string]: number;
    };
  
    // largest sale in order value or percently (items only). Format N | N%
    maximalSale: {
      [currency: string]: string | null;
    };
  
    // credit names
    names: {
      singular: string | null;
      plural: string | null;
      /* eslint-disable-next-line camelcase*/
      plural_2: string | null;
    };
  
    // credits for registration - credit amount
    actionRegistration: number;
  
    // credits for add to mailing list - credit amount
    actionMailingList: number;
  
    creditComputationMode: CreditComputationMode;

    // shoptet coupon shipping price parameter
    shoptetCouponShippingPrice: string | null;
  
    // show on shoptet frontend
    showOnFrontend: ShowOnFrontendMode;
  
    // credit's expiration date
    expirationDays: number;

    // delayed recharge
  delayDaysBeforeRecharge: number;

  // order filter, insensitive, using ^$ and * replaced by .*
  orderFilter: {
    operand: "AND" | "OR";
    expressions: { property: string; operator: "==" | "!="; value: string }[];
  } | null;

  // get coef priority
  coefficientPriority: (
    | "group"
    | "product"
    | "brand"
    | "category"
    | "default"
  )[];
  }
  
  interface InfoPageSettings {
    // bonus program eshop page url
    eshopPagePath: string | null;
  }
  
  interface CustomerGroupsSettings {
    // allow anonymous clients
    allowAnonymous: boolean;
  }

  
interface SmartmaillingSettings {
  smartmailling: {
    listId: number | null;
    fieldIds: {
      [name: string]: number;
    } | null;
    username: string | null;
    apiKey: string | null;
    lastFullImport: Date | null;
    taskId: string | null;
  } | null;
}

interface LeadhubSettings {
  leadhub: {
    apiKey: string | null;
    activated: boolean;
    expireAfterDays: number | null;
    lastFullImport: Date | null;
    taskId: string | null;
  } | null;
}

interface MaillingSettings {
  mailling: {
    // pin to anonymous
    pinEmail: "inapp" | "integration";
  };
}
  
  interface OtherSettings {
    // credits for product review - credit amount
    actionProductReview: number;
  
    // percentual coefficient for friend's paid order - how much percent from frient credits recharge to me
    actionFriendOrder: number;
  }
  
  export class AddonConverter implements firebase.firestore.FirestoreDataConverter<Addon> {
    toFirestore(addon: Addon): firebase.firestore.DocumentData {
      throw new Error("Update addon not allowed")
    }
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      options?: firebase.firestore.SnapshotOptions | undefined
    ): Addon {
      const data = snapshot.data();
      return {
        id: snapshot.id,
        businessId: data.businessId,
        state: data.state,
        configured: data.configured,
        initializationError: data.initializationError,
        initializationFinishedon: data.initializationFinishedon
          ? data.initializationFinishedon.toDate()
          : null,
        logoUrl: data.logoUrl,
        lastSyncedon: data.lastSyncedon
          ? moment(data.lastSyncedon.toDate()).format(
              "DD.MM.YYYY HH:mm"
            )
          : "-",
        syncTaskId: data.syncTaskId,
  
        warnings: data.warnings,
  
        settings: {
          currencies: data.detail.currencies,
          eshopPagePath: data.detail.eshopPagePath,
          allowAnonymous: data.detail.allowAnonymous,
          discharge: data.detail.discharge,
          actionPayOrder: data.detail.actionPayOrder,
          dischargeMinimalTotal: data.detail.dischargeMinimalTotal,
          maximalSale: data.detail.maximalSale,
          actionMailingList: data.detail.actionMailingList,
          actionRegistration: data.detail.actionRegistration,
          creditComputationMode: data.detail.creditComputationMode,
          expirationDays: data.detail.expirationDays,
          delayDaysBeforeRecharge: data.detail.delayDaysBeforeRecharge || 0,
          orderFilter: data.detail.orderFilter || null,
          names: data.detail.names,
          shoptetCouponShippingPrice: data.detail.shoptetCouponShippingPrice,
          showOnFrontend: data.detail.showOnFrontend,
          smartmailling: data.detail.smartmailling
            ? {
                ...data.detail.smartmailling,
                lastFullImport:
                  data.detail.smartmailling.lastFullImport &&
                  data.detail.smartmailling.lastFullImport.toDate
                    ? data.detail.smartmailling.lastFullImport.toDate()
                    : data.detail.smartmailling.lastFullImport,
              }
            : null,
          leadhub: data.detail.leadhub
            ? {
                ...data.detail.leadhub,
                lastFullImport:
                  data.detail.leadhub.lastFullImport &&
                  data.detail.leadhub.lastFullImport.toDate
                    ? data.detail.leadhub.lastFullImport.toDate()
                    : data.detail.leadhub.lastFullImport,
              }
            : null,
          mailling: data.detail.mailling || {
            pinEmail: "inapp",
          },
          licence: data.detail.licence
            ? {
                plan: data.detail.licence.plan,
                features: data.detail.licence.features,
                overlimits: data.detail.licence.overlimits || [],
              }
            : {
                plan: Plan.FREE,
                features: [],
                overlimits: [],
              },
          coefficientPriority: data.detail.coefficientPriority || [
            "group",
            "product",
            "brand",
            "category",
            "default",
          ],
        },
      };
    }
  }
