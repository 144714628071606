



































import Vue from "vue";
import { defineComponent, Ref, ref, watch } from "@vue/composition-api";
import CollectionViewer from "@/components/CollectionViewer.vue";
import { CollectionState } from "@/composables/collection/types";
import i18n from "@/plugins/i18n";
import firebaseService from "@/services/firebase";
import { NewConverter } from "@/composables/new/converters";
import moment from "moment";
import useCollection from "@/composables/collection";
import { New, NewEditable } from "@/composables/new/types";
import DetailDialog from "@/components/DetailDialog.vue";
import NewEditor from "@/components/NewEditor.vue";
import { v4 as uuid } from "uuid";
import useUser from "@/composables/user";
export default defineComponent({
  components: {
    CollectionViewer,
    DetailDialog,
    NewEditor,
  },
  setup(props) {
    const { user } = useUser();

    const newEditorDialog = ref(false);
    const newEditable: Ref<NewEditable | null> = ref(null);
    const currentNew: Ref<New | null> = ref(null);

    const editNew = async (newEntry: New) => {
      newEditable.value = newEntry.edit();
      newEditorDialog.value = true;
    };
    const updateNew = async () => {
      await newEditable.value!.updateOriginal();
      await collectionComposable.refresh();
    };
    const deleteNew = async (newEntry: New) => {
      await firebaseService.deleteNew(newEntry.id);
      await collectionComposable.refresh();
    };
    
    const collectionComposable = useCollection({
      sortBy: "date",
      sortDesc: true,
      filters: {
      },
      queryFactory: (state: CollectionState) => {       
        let query: any = firebaseService.getDb().collection("news");       

        query = query.orderBy(state.sortBy, state.sortDesc ? "desc" : "asc");

        return query;
      },
      converter: new NewConverter(),
      itemHandler: undefined,
    });
    const collectionOptions = {
      title: null,
      collection: "news",
      hideSearch: true,
      headers: [
        {
          text: i18n.t("news.date"),
          value: "date",
          sortable: false,
          customSlot: "item.date",
          customGetter: (item: any) => {
            return moment(item.date).format("YYYY-MM-DD");
          },
        },
        { text: i18n.t("news.title"), value: "title", sortable: false, },
        { text: i18n.t("news.link"), value: "link", sortable: false, },        
      ],
      itemsActions: [
        {
          icon: "far fa-edit",
          color: "secondary",
          handler: editNew,
          title: i18n.t('news.editNew')
        },
        {
          icon: "fas fa-times",
          color: "red",
          handler: deleteNew,
          title: i18n.t('news.deleteNew')
        },
      ],
      collectionActions: [
        {
          icon: "fas fa-plus",
          color: "secondary",
          disabled: false,
          loading: false,
          tooltip: i18n.t("news.addNew"),
          handler: async () => {
            const newNew = new New({
              id: uuid(),
              title: "",
              description: "",
              link: "",
              date: moment().toDate(),              
            });

            await editNew(newNew);
          },
        },
      ],
      collectionComposable,
    };

    return {
      collectionOptions,

      newEditorDialog,
      newEditable,
      currentNew,
      updateNew,
    };
  },
});
