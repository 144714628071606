import stateFactory from './state'
import gettersFactory from './getters'
import actionsFactory from './actions'
import mutationFactory from "./mutations"
import { UserActions, UserGetters, UserMutations, UserState } from "./types"
import { toRefs } from '@vue/composition-api'

let useUserInitialized = false;
let sharedState: UserState;
let getters: UserGetters;
let mutations: UserMutations;
let actions: UserActions;

export default function useUser() {

    if (false == useUserInitialized) {
        sharedState = stateFactory();
        getters = gettersFactory(sharedState);
        mutations = mutationFactory(sharedState);
        actions = actionsFactory(sharedState, mutations);       

        useUserInitialized = true;
    }

    return {
        ...toRefs(sharedState),
        ...getters!,
        ...actions!
    }
}