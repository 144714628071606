import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import VueCompositionAPI from '@vue/composition-api'
import './styles/main.scss';
import { Chart, registerables  } from 'chart.js';
Chart.register(...registerables );

Vue.config.productionTip = false

Vue.use(VueCompositionAPI);

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')