import { computed,  } from "@vue/composition-api"
import { CollectionGetters, CollectionState } from "./types";

export default function gettersFactory(state: CollectionState): CollectionGetters {    
    const canNext = computed(() => { return null != state.last });    
    const canPrev = computed(() => { return state.page > 1 });     
    return {
        canNext,
        canPrev
    }
}