import { UserActions, UserMutations, UserState } from './types'
import firebaseService from '@/services/firebase'
import firebase from 'firebase/app'

export default function actionsFactory(state: UserState, mutations: UserMutations) : UserActions {

    async function initAuthentication(): Promise<void> {
        const user = await firebaseService.waitOnAuthentication();
        const adminRole = await getAdminRole(user);
        const hasRole = null != adminRole;
        if(hasRole) {
            mutations.MUTATION_SET_CURRENT({
                id: user!.uid,
                email: user!.email,
                displayName: user!.displayName,
                role: adminRole!
            });         
        } else {
            await firebaseService.logout();
            mutations.MUTATION_SET_CURRENT(null);            
        }
    }

    async function getAdminRole(user: firebase.User | null): Promise<string | null> {
        if(user) {
            const token = await user.getIdTokenResult();
            return token.claims['role'] || null;            
        } else {
            return null;
        }        
    }

    async function login(email: string, password: string): Promise<void> {
        try {
            const credentials = await firebaseService.login(email, password);
            const user = credentials.user!;
            const adminRole = await getAdminRole(user);
            const hasRole = null != adminRole;
            if(hasRole) {
                mutations.MUTATION_SET_CURRENT({
                    id: user.uid,
                    email: user.email,
                    displayName: user.displayName,
                    role: adminRole!
                });
            } else {
                await firebaseService.logout();
                throw new Error('not admin');
            }
            
        } catch(error) {
            mutations.MUTATION_SET_CURRENT(null);
        }
    }
   
    async function logout(): Promise<void> {
        await firebaseService.logout();
        mutations.MUTATION_SET_CURRENT(null);
    }

    return {
        initAuthentication,
        login,
        logout
    }
}