import firebase from "firebase/app";
import { Todo } from "./types"

export class TodoConverter implements firebase.firestore.FirestoreDataConverter<Todo> {
    toFirestore(todo: Todo): firebase.firestore.DocumentData {
        throw new Error('not implemented');  
    }
    fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>, options: firebase.firestore.SnapshotOptions): Todo {
        const data = snapshot.data();
        return new Todo({
            id: snapshot.id,   
            description: data.description,
            createdon: data.createdon instanceof firebase.firestore.Timestamp ? data.createdon.toDate() : data.createdon,
            finishedon: data.finishedon instanceof firebase.firestore.Timestamp ? data.finishedon.toDate() : data.finishedon,
            done: data.done,
            assignedTo: data.assignedTo,
            assignedToId: data.assignedToId,
            businessId: data.businessId,
            priority: data.priority,
            note: data.note,         
        })
    }

}
