


























































































































































































import { defineComponent, ref, watch, Ref } from "@vue/composition-api";
import CollectionViewer from "@/components/CollectionViewer.vue";
import {
  AmountType,
  Business,
  BusinessConverter,
  BusinessEditable,
  Feature,
  InvoicingType,
} from "@/composables/business/types";
import firebaseService from "@/services/firebase";
import DetailDialog from "@/components/DetailDialog.vue";
import BusinessEditor from "@/components/BusinessEditor.vue";
import moment from "moment";
import useUser from "@/composables/user";
export default defineComponent({
  components: {
    CollectionViewer,
    DetailDialog,
    BusinessEditor,
  },
  setup(props) {
    const { user } = useUser();
    const searchTerm: Ref<string | null> = ref(null);
    const searchInputChanged = (input: string) => {
      searchTerm.value = input;
    };
    watch(searchTerm, () => {
      if (searchTerm.value) {
        queryBusiness();
      } else {
        results.value = [];
      }
    });

    const isSearching = ref(false);

    const results: Ref<{
      id: string;
      addonId: string;
      eshopUrl: string;
      company: string;
      contactEmail: string;
      billingEmail: string;
      shoptetAddonState: string;
      plan: string;
      features: string[];
      business: Business;
      trial: string;
      subId: string;
      fakturoidId: string;
      stripeId: string;
      eshopId: string;
    }[]> = ref([]);

    const queryBusiness = async () => {
      isSearching.value = true;

      const { data } = await firebaseService.admin("getAdminReportingData", {
        reportType: "query-accounts",
        parameters: {
          searchTerm: searchTerm.value,
        },
      });

      console.log(data.results.length)
      const res = [];
      for (let i = 0; i < data.results.length; i++) {
        const item = data.results[i];
        const newResult = {
          ...item,
          business: await loadBusiness(item.id),
        };

        if (newResult.business) {
          const trialEndAt = moment(newResult.business.createdAt).add(
            30,
            "days"
          );
          const daysFromTrial = trialEndAt.diff(moment(), "days");
          newResult.trial =
            daysFromTrial > 0
              ? `aktivní do ${trialEndAt.format(
                  "DD.MM.YYYY"
                )} (${daysFromTrial} dnů)`
              : `ukončena ${trialEndAt.format(
                  "DD.MM.YYYY"
                )} (před ${daysFromTrial * -1} dny)`;

          if (newResult.plan == "free") {
            if (newResult.features.length == 1) {
              newResult.plan = "free-zero";
            }
          } else {
            newResult.trial += ' - plán již vybrán'
          }

          res.push(newResult);
        }
      }

      results.value = res;
      isSearching.value = false;
    };

    const businessConverter = new BusinessConverter();
    const loadBusiness = async (id: string) => {
      const snap = await firebaseService
        .getDb()
        .collection("businesses")
        .doc(id)
        .withConverter(businessConverter)
        .get();
      console.log(snap);
      return snap.data();
    };

    const businessEditorDialog = ref(false);
    const businessEditable: Ref<BusinessEditable | null> = ref(null);
    const editBusiness = async (business: Business) => {
      businessEditable.value = {
        original: business,
        contact: {
          email: business.contact.email,
          phone: business.contact.phone,
        },
        billing: business.billing
          ? {
              activatedAt: business.billing.activatedAt,
              stripe: business.billing.stripe
                ? {
                    couponId: business.billing.stripe.couponId,
                  }
                : null,
              invoicingType: business.billing.invoicingType,
              amountType: business.billing.amountType,
              payType: business.billing.payType,
              fixAmount: business.billing.fixAmount,
              prepayMetadata: business.billing.prepayMetadata
                ? {
                    firstDayOfNextPaymentMonth:
                      business.billing.prepayMetadata
                        .firstDayOfNextPaymentMonth,
                    hasAnnualPrices:
                      business.billing.prepayMetadata.hasAnnualPrices,
                    licencePrice: business.billing.prepayMetadata.licencePrice,
                    numberOfMonths:
                      business.billing.prepayMetadata.numberOfMonths,
                  }
                : null,
              blockedByPayment: business.billing.blockedByPayment,
              monthlyDiscount: business.billing.monthlyDiscount,
            }
          : null,
      };
      businessEditorDialog.value = true;
    };

    const updateBusiness = async () => {
      const original = businessEditable.value!.original;
      original.contact.email = businessEditable.value!.contact.email!;
      original.contact.phone = businessEditable.value!.contact.phone!;
      if (original.billing) {
        if (original.billing.stripe) {
          original.billing.stripe.couponId = businessEditable.value!.billing!.stripe!.couponId;
        }
        original.billing.activatedAt = businessEditable.value!.billing!.activatedAt;
        original.billing.invoicingType = businessEditable.value!.billing!.invoicingType;
        original.billing.amountType = businessEditable.value!.billing!.amountType;
        original.billing.payType = businessEditable.value!.billing!.payType;
        original.billing.fixAmount = businessEditable.value!.billing!.fixAmount;
        original.billing.monthlyDiscount = businessEditable.value!.billing!.monthlyDiscount;
        original.billing.prepayMetadata = businessEditable.value!.billing!
          .prepayMetadata
          ? {
              firstDayOfNextPaymentMonth: businessEditable.value!.billing!
                .prepayMetadata.firstDayOfNextPaymentMonth,
              hasAnnualPrices: businessEditable.value!.billing!.prepayMetadata
                .hasAnnualPrices,
              licencePrice: businessEditable.value!.billing!.prepayMetadata
                .licencePrice,
              numberOfMonths: businessEditable.value!.billing!.prepayMetadata
                .numberOfMonths,
            }
          : null;
        original.billing.blockedByPayment = businessEditable.value!.billing!.blockedByPayment;

        if (
          original.billing!.invoicingType == InvoicingType.prepay &&
          original.billing!.amountType == AmountType.plan
        ) {
          throw new Error("Předplatné na základě tržeb pouze pres konzoli klienta.");
        }
      }
      await firebaseService.updateBusiness(businessEditable.value!.original);
    };

    const loginAsBusiness = async (businessId: string, language: string) => {
      try {
        const result = await firebaseService.business("getAdminLoginUrl", {
          businessId,
          language,
        });
        window.open(result.link, "_blank");
      } catch (error) {
        console.log(error);
      }
    };

    const exportAccounts = async () => {
      const csv = await firebaseService.admin("getAdminReportingData", {
        reportType: "export-accounts",
      });
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(csv.data)
      );
      element.setAttribute("download", "ellity-accounts.csv");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    const copyToClipboard = (str: string) => {
      window.navigator.clipboard.writeText(str);
    };

    const addingExtendedSupport = ref(false);
    const enableExtendedSupport = async (item: {addonId: string, features: Feature[]})  => {
      try {
        addingExtendedSupport.value = true;
        await firebaseService.bonus('updateLicence', { addonId: item.addonId, type: 'add-feature', data: Feature.EXTENDED_SUPPORT});
        item.features.push(Feature.EXTENDED_SUPPORT);
      } finally {
        addingExtendedSupport.value = false;
      }
    }

    return {
      businessEditorDialog,
      businessEditable,
      updateBusiness,
      loginAsBusiness,
      searchTerm,
      searchInputChanged,
      exportAccounts,

      editBusiness,

      results,
      isSearching,

      copyToClipboard,
      
      enableExtendedSupport,
      addingExtendedSupport,
      Feature,

      user
    };
  },
});
