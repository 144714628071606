






















































































































































































































































































































































import {
  defineComponent,
  PropType,
  watch,
  ref,
  Ref,
} from "@vue/composition-api";
import firebaseService from "@/services/firebase";
import moment from "moment";
import {
  Addon,
  AmountType,
  BusinessEditable,
  InvoicingType,
  PayType,
} from "@/composables/business/types";
import { BarChart } from "vue-chart-3";
export default defineComponent({
  props: {
    business: Object as PropType<BusinessEditable>,
  },
  components: { BarChart },
  setup(props, { emit }) {
    watch(
      () => props.business,
      () => {
        emit("modelChanged");
      },
      { deep: true }
    );

    const addon: Ref<Addon | null> = ref(null);
    firebaseService
      .getBusinessAddons(props.business!.original.id)
      .then((addons: Addon[]) => {
        addon.value = addons[0];
      });

    const invoicingType = ref(
      props.business!.billing ? props.business!.billing.invoicingType : null
    );
    watch(invoicingType, () => {
      props.business!.billing!.invoicingType = invoicingType.value!;

      if (invoicingType.value == InvoicingType.prepay) {
        amountType.value = AmountType.fix;
        props.business!.billing!.prepayMetadata = {
          hasAnnualPrices: false,
          licencePrice: 250,
          numberOfMonths: 6,
          firstDayOfNextPaymentMonth: moment()
            .add(1, "months")
            .startOf("month")
            .toDate(),
        };
        date.value = moment(
          props.business!.billing!.prepayMetadata.firstDayOfNextPaymentMonth
        ).format("YYYY-MM");
      } else {
        props.business!.billing!.prepayMetadata = null;
        date.value = null;
      }
    });
    const invoicingTypes = [
      {
        id: InvoicingType.back,
        name: "Zpětně za měsíc",
      },
      {
        id: InvoicingType.prepay,
        name: "Předplatné",
      },
    ];

    const amountType = ref(
      props.business!.billing ? props.business!.billing.amountType : null
    );
    watch(amountType, () => {
      props.business!.billing!.amountType = amountType.value!;
      if (amountType.value == AmountType.plan) {
        fixAmount.value = null;
      } else {
        fixAmount.value = 250;
      }
    });
    const amountTypes = [
      {
        id: AmountType.plan,
        name: "Plán",
      },
      {
        id: AmountType.fix,
        name: "Fix",
      },
    ];

    const payType = ref(
      props.business!.billing ? props.business!.billing.payType : null
    );
    watch(payType, () => {
      props.business!.billing!.payType = payType.value!;
    });
    const payTypes = [
      {
        id: PayType.card,
        name: "Karta",
      },
      {
        id: PayType.transfer,
        name: "Převod",
      },
    ];

    const fixAmount: Ref<number | null> = ref(
      props.business!.billing ? props.business!.billing!.fixAmount : null
    );
    watch(fixAmount, () => {
      props.business!.billing!.fixAmount = fixAmount.value;
      if (props.business!.billing!.prepayMetadata) {
        props.business!.billing!.prepayMetadata.licencePrice = fixAmount.value!;
      }
    });

    const monthlyDiscount: Ref<number | null> = ref(
      props.business!.billing ? props.business!.billing!.monthlyDiscount : null
    );
    watch(monthlyDiscount, () => {
      props.business!.billing!.monthlyDiscount = monthlyDiscount.value;      
    });

    const changeCouponId = (couponId: string | null) => {
      props.business!.billing!.stripe!.couponId = couponId;
    };
    const discountCoupons: Ref<{ id: string; name: string }[]> = ref([]);
    const isDiscountCouponsLoading = ref(false);
    const loadDiscountCoupons = async () => {
      try {
        isDiscountCouponsLoading.value = true;

        const result = await firebaseService.admin("getAdminReportingData", {
          reportType: "discount-coupons",
          parameters: {},
        });
        discountCoupons.value = [
          ...result.coupons.map((item: any) => ({
            id: item.id,
            name: item.name,
          })),
        ];
      } finally {
        isDiscountCouponsLoading.value = false;
      }
    };

    const minDate = ref(moment().format("YYYY-MM"));
    const date = ref(
      props.business!.billing && props.business!.billing.prepayMetadata
        ? moment(
            props.business!.billing.prepayMetadata.firstDayOfNextPaymentMonth
          ).format("YYYY-MM")
        : null
    );
    const onDateChanged = (v: string) => {
      const dt = moment(v, "YYYY-MM-01").toDate();
      date.value = v;
      props.business!.billing!.prepayMetadata!.firstDayOfNextPaymentMonth = dt;
    };

    const block = ref(
      props.business!.billing ? props.business!.billing.blockedByPayment : false
    );
    watch(block, () => {
      props.business!.billing!.blockedByPayment = block.value!;
    });
    const blockItems = [
      {
        id: true,
        name: "Zablokováno",
      },
      {
        id: false,
        name: "Billing v pořádku",
      },
    ];

    const activateAtSave = ref(false);
    watch(activateAtSave, () => {
      if (activateAtSave.value) {
        payType.value = PayType.transfer;
        props.business!.billing!.activatedAt = moment().toDate();
      } else {
        props.business!.billing!.activatedAt = null;
      }
    });
    const activatedAt = ref(
      props.business!.billing && props.business!.billing.activatedAt
        ? moment(props.business!.billing.activatedAt).format("DD.MM.YYYY")
        : null
    );

    const isLoadingStats = ref(false);
    const statsChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: "#fff",
      scales: {
        x: {
          grid: {
            display: false,
          },
          title: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: false,
          },
          ticks: {
            stepSize: 10,
          },
        },
      },
      locale: "cs",
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
    };
    const ordersStatsChartData: any = {
      labels: [],
      datasets: [
        {
          label: "orders",
          data: [],
          backgroundColor: "#9CCC65",
        },
      ],
    };
    const customersStatsChartData: any = {
      labels: [],
      datasets: [
        {
          label: "customers",
          data: [],
          backgroundColor: "#9CCC65",
        },
      ],
    };
    const creditsStatsChartData: any = {
      labels: [],
      datasets: [
        {
          label: "recharge",
          data: [],
          backgroundColor: "#9CCC65",
        },
        {
          label: "discharge",
          data: [],
          backgroundColor: "#EF5350",
        },
        {
          label: "expire",
          data: [],
          backgroundColor: "#78909C",
        },
      ],
    };
    const loadStats = async () => {
      isLoadingStats.value = true;
      const result = await firebaseService.admin("getAdminReportingData", {
        reportType: "account-stats",
        parameters: {
          searchTerm: props.business?.original.id,
          fromDate: moment()
            .startOf("month")
            .add(-3, "months")
            .format("YYYY-MM"),
          toDate: moment()
            .startOf("month")
            .format("YYYY-MM"),
        },
      });

      let sortedDates = Object.keys(result.orders).sort();
      let listX = [];
      let listY = [];

      for (let i = 0; i < sortedDates.length; i++) {
        const x = sortedDates[i];
        const y = result.orders[x];
        listX.push(x);
        listY.push(y);
      }
      ordersStatsChartData.labels = listX;
      ordersStatsChartData.datasets[0].data = listY;

      sortedDates = Object.keys(result.customers).sort();
      listX = [];
      listY = [];

      for (let i = 0; i < sortedDates.length; i++) {
        const x = sortedDates[i];
        const y = result.customers[x];
        listX.push(x);
        listY.push(y);
      }
      customersStatsChartData.labels = listX;
      customersStatsChartData.datasets[0].data = listY;

      sortedDates = Object.keys(result.credits).sort();
      listX = [];
      listY = [];
      const listY2 = [];
      const listY3 = [];

      for (let i = 0; i < sortedDates.length; i++) {
        const x = sortedDates[i];
        const y = result.credits[x];
        listX.push(x);
        listY.push(y.recharged);
        listY2.push(y.discharged);
        listY3.push(y.expired);
      }
      creditsStatsChartData.labels = listX;
      creditsStatsChartData.datasets[0].data = listY;
      creditsStatsChartData.datasets[1].data = listY2;
      creditsStatsChartData.datasets[2].data = listY3;

      isLoadingStats.value = false;
    };

    const isExportingStats = ref(false);
    const exportStats = async () => {
      let csv = "";

      isExportingStats.value = true;
      const result = await firebaseService.admin("getAdminReportingData", {
        reportType: "account-stats",
        parameters: {
          searchTerm: props.business?.original.id,
          fromDate: moment()
            .startOf("month")
            .add(-12, "months")
            .format("YYYY-MM"),
          toDate: moment()
            .startOf("month")
            .format("YYYY-MM"),
        },
      });

      let sortedDates = Object.keys(result.orders).sort();
      let listX = [];
      let listY = [];
      for (let i = 0; i < sortedDates.length; i++) {
        const x = sortedDates[i];
        const y = result.orders[x];
        listX.push(x);
        listY.push(y);
      }
      csv += "orders;\r\n";
      listX.forEach((item) => {
        csv += `${item};`;
      });
      csv += "\r\n";
      listY.forEach((item) => {
        csv += `${item};`;
      });
      csv += "\r\n";

      sortedDates = Object.keys(result.customers).sort();
      listX = [];
      listY = [];
      for (let i = 0; i < sortedDates.length; i++) {
        const x = sortedDates[i];
        const y = result.customers[x];
        listX.push(x);
        listY.push(y);
      }

      csv += "\r\n";
      csv += "customers;\r\n";
      listX.forEach((item) => {
        csv += `${item};`;
      });
      csv += "\r\n";
      listY.forEach((item) => {
        csv += `${item};`;
      });
      csv += "\r\n";

      sortedDates = Object.keys(result.credits).sort();
      listX = [];
      listY = [];
      const listY2 = [];
      const listY3 = [];

      for (let i = 0; i < sortedDates.length; i++) {
        const x = sortedDates[i];
        const y = result.credits[x];
        listX.push(x);
        listY.push(y.recharged);
        listY2.push(y.discharged);
        listY3.push(y.expired);
      }

      csv += "\r\n";
      csv += "credits;\r\n";
      csv += "type;";
      listX.forEach((item) => {
        csv += `${item};`;
      });
      csv += "\r\n";
      csv += "recharged;";
      listY.forEach((item) => {
        csv += `${item};`;
      });
      csv += "\r\n";
      csv += "discharged;";
      listY2.forEach((item) => {
        csv += `${item};`;
      });
      csv += "\r\n";
      csv += "expired;";
      listY3.forEach((item) => {
        csv += `${item};`;
      });
      csv += "\r\n";

      isExportingStats.value = false;

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
      );
      element.setAttribute("download", "ellity-unit-report.csv");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    };

    loadDiscountCoupons();
    loadStats();
    return {
      isDiscountCouponsLoading,
      discountCoupons,
      changeCouponId,

      addon,

      InvoicingType,
      invoicingType,
      invoicingTypes,

      AmountType,
      amountType,
      amountTypes,

      payTypes,
      payType,

      fixAmount,

      monthlyDiscount,

      minDate,
      date,
      onDateChanged,

      block,
      blockItems,

      activatedAt,
      activateAtSave,

      isLoadingStats,
      ordersStatsChartData,
      customersStatsChartData,
      creditsStatsChartData,
      statsChartOptions,

      exportStats,
      isExportingStats,
    };
  },
});
