import firebaseService from "@/services/firebase";
import firebase from "firebase/app";
export class Todo {
    id!: string;
    description!: string;
    createdon!: Date| firebase.firestore.Timestamp;
    finishedon!: Date | null| firebase.firestore.Timestamp;
    done!: boolean;
    assignedTo!: string | null;
    assignedToId!: string | null;
    businessId!: string | null;
    priority!: number;
    note!: string | null;

    constructor(data: Partial<Todo>) {
        Object.assign(this, data);
    }    

    edit(): TodoEditable {
        return new TodoEditable(this);
    }
}


export class TodoEditable {
    original: Todo;

    description: string;
    finishedon: Date | null| firebase.firestore.Timestamp;
    done: boolean;
    assignedTo: string | null ;
    assignedToId: string | null;
    priority: number;
    note: string | null;

    constructor(todo: Todo) {
        this.original = todo;

        this.description = todo.description;
        this.finishedon = todo.finishedon;
        this.done = todo.done;
        this.assignedTo = todo.assignedTo;
        this.assignedToId = todo.assignedToId;
        this.priority = todo.priority;
        this.note = todo.note;
    }

    async updateOriginal(): Promise<void> {
        this.original.description = this.description;
        this.original.finishedon = this.finishedon;
        this.original.done = this.done;
        this.original.assignedTo = this.assignedTo;
        this.original.assignedToId = this.assignedToId;
        this.original.priority = this.priority;
        this.original.note = this.note;

        await firebaseService.mergeTodo(this.original.id, {
           description: this.description,
           createdon:  firebase.firestore.Timestamp.fromDate(this.original.createdon as Date),
           finishedon: this.finishedon ? firebase.firestore.Timestamp.fromDate(this.finishedon as Date) : null,
           done: this.done,
           assignedTo: this.assignedTo,
           assignedToId: this.assignedToId,
           priority: this.priority,
           note: this.note,
        })
    }
}