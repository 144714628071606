export default {
    '404': {
        message: 'Tady jsi špatně',
        btn: 'Honem zpátky do adminu'
    },
    login: {
        username: 'Uživatelské jméno',
        usernameRequired: 'Uživatelské jméno požadováno',
        password: 'Heslo',
        passwordRequired: 'Heslo požadováno',
        failed: 'Nepovedlo se',
        submitBtn: 'Přihlásit se'
    },
    navigation: {
        home: 'Dashboard',
        tasks: 'Úlohy',
        shoptet: "Shoptet",
        businesses: 'Klienti',
        news: 'Novinky',
        logout: 'Odhlásit se',
        calendarSubmit: 'Potvdit',
        calendarCancel: 'Zavřít',
        map: 'Map'
    },
    collectionViewer: {
        searchTermLabel: 'Hledat',
        loadingLabel: 'Prosím čekej',
        noDataText: 'Nic tu není'
    },
    detailDialog: {
        save: 'Uložit',
        confirm: 'Potvdit',
        unsavedChanges: 'Neuložené změny',
        unconfirmedChanges: 'Nepotrvzené změny',
        back: 'Zpět',
        close: 'Přesto zavřít',
        changesWillNotBeSaved: 'Změny nebudou uloženy!',
        changesWillNotBeConfirmed: 'Změny nebudou potvrzeny!',
        repairInputAndSaveAgain: 'Opravte chyby zadání a opakujte uložení.',
        repairInputAndConfirmAgain: 'Opravte chyby zadání a opakujte potvrzení.',
        errorMessage: 'Něco je špatně: {msg}',
        progressMessage: 'Prosím čekej ...',
    },    
    businesses: {
        state: "Stav účet/doplněk",
        searchTermLabel: 'Hledat e-shop, e-mail, id',
        email: 'E-mail',
        phone: 'Telefon',
        fixFee: 'Fixní tarif',
        monthlyDiscount: 'Měsíční sleva',
        nextInvoiceDate: 'Datum další faktury',
        type: 'Typ fakturace',
        discount: 'Sleva',
        eshop: 'Eshop url',
        id: 'E-shop id',
        guid: "Id",
        name: 'E-shop název',
        status: 'Aktivace',
        editTitle: 'Editace klienta',
        informationSection: 'Informace',
        activationSection: 'Operace s účtem',
        contactSection: 'Kontakt',
        invoiceSection: 'Billing',
        activated: 'Aktivní',
        blocked: 'Blokovaný',
        billingInit: 'Billing',       
        configureAddon: 'Spustit onboarding jako klient',
        loginAsBusiness: 'Přihlásit se jako klient',
        subscriptionStatusactive: 'Nainstalován, aktivní',
        subscriptionStatusincomplete: 'Nejsou nastaveny',
        subscriptionStatuspast_due: 'Poslední platba nebyla provedena',       
        activateBtn: 'Aktivovat účet',
        deactivateBtn: 'Zablokovat účet',
        resetPasswordBtn: 'Generovat reset hesla',
        metadataCash: 'Průměrný měsíční obrat (metadata)',
       filterAll: "Vše",
       amountType: "Typ",
       amountTypePlan: "Tržba",
       amountTypeFix: "Fix",
       payType: "Platební metoda",
       payTypeCard: "Karta",
       payTypeTransfer: "Převod",
       invoicingType: "Fakturace",
       invoicingTypeBack: "Zpětně měsíc",
       invoicingTypePrepay: "Předplatné",
        filterYes: "Ano",
        filterNo: "Ne",
        activatedFilter: "Aktivované platební údaje",
        blockedFilter: "Blokované",
        noBillingFilter: "Bez billingu",
        stripeId: "Stripe ID",
        fakturoidId: "Fakturoid ID",
        addon: "Doplňek",
        numberOfMonths: "Počet měsíců",
        firstDayOfNextPaymentMonth: "Měsíc další platby (automaticky 1. den)",
        block: "Blokování billingu (po selhání platby)",
        subId: "SUB ID",
        vatId: "VAT ID",
        address: "Address",
    },
    addons: {
        warnings: "Varování",

        name: 'Název',
        state: 'Stav',
        trial: 'Trial končí',
        configured: 'Onboarded',
        template: 'Šablona',
        eshopUrl: 'E-shop',
        
        addonStateAll: 'Vše',
        addonStateUninstalled: 'Odinstalované',
        addonStateSuspended: 'Pozastavené',
        addonStateInstalled: 'Instalující',
        addonStateActive: 'Aktivní',
        addonStateErrorAccount: 'Chyba účtu',
        addonStateErrorTemplate: 'Chyba šablony',
        addonStateErrorInit: 'Chyba initu',
            
        active: 'Nainstalován a aktivní',
        uninstalled: 'Odinstalovaný',
        suspended: 'Pozastavený',
        installed: "Instalující",
        blocked: 'Blokován billingem',
    },
    tasks: {
        description: 'Popis',
        createdon: 'Vytvořeno',  
        finishedon: 'Dokončeno',
        priority: 'Priorita',
        assignedTo: 'Přiřazeno',
        note: 'Poznámka',
        done: 'Hotovo',
        business: 'Klient',
        todoStatePending: 'Ke zpracování',
        todoStateDone: 'Dokončené',
        todoStateAll: 'Všechny úlohy',
        editTitle: 'Editace úlohy',
        addNew: 'Nová úloha',    
        someone: 'Kdokoliv',
        todoAssignedToMe: 'Přiřazeno mě' ,
        todoAssignedToAll: 'Přiřazeno komukoliv' ,
    },
    news: {
        title: 'Nadpis',
        description: 'Obsah',
        date: 'Datum',
        link: 'Odkaz',
        editTitle: 'Editace novinky',
        addNew: 'Přidat novinku',
        editNew: 'Editovat',
        deleteNew: 'Odstranit'
    },
    home: {
        currentPaymentTitle: 'Aktuální platba',        
        businessesCountTitle: 'Účty',                
        bonusCountTitle: 'Doplňky',        
        title: 'Dashboard',
        upgradeTitle: 'Marketplace',
        rechargesAndDischargesTitle: 'Celkový přehled kreditu',
        rechargeTitle: 'Nabití celkem',
        dischargeTitle: 'Čerpání celkem',
        rechargeLegend: 'Nabití',
        dischargeLegend: 'Čerpání',
        rechargeTrend: 'Trend nabíjení',
        dischargeTrend: 'Trend čerpání',
        rechargesAndDischargesYTitle: 'Kredity',
        rechargesAndDischargesXTitle: 'Čas',            

        reloadBtn: 'Aktualizovat',
        rangeBtn: 'Období',
        dayBtn: 'Den',
        weekBtn: 'Týden',
        monthBtn: 'Měsíc',
        yearBtn: 'Rok',
        
        newsTitle: 'Novinky v Ellity',
        moreNewsBtn: 'Více novinek',
        datePickerSubmit: 'Potvrdit',
        datePickerCancel: 'Zavřít',
        chartDescription: 'Data přehledu jsou získávána v reálném čase',

        timePeriodRangeText: 'Období',
        timePeriodDayText: 'Dnes',
        timePeriodWeekText: 'Tento týden',
        timePeriodMonthText: 'Tento měsíc',
        timePeriodYearText: 'Tento rok',
        timePeriodlastRangeText: 'Minulé období',
        timePeriodlastDayText: 'Včera',
        timePeriodlastWeekText: 'Minulý týden',
        timePeriodlastMonthText: 'Minulý měsíc',
        timePeriodlastYearText: 'Minulý rok',

        lastDay: 'za den',
        lastWeek: 'za týden',
        lastMonth: 'za měsíc',
        lastYear: 'za rok',
        lastRange: 'za období',

        revenuesTitle: 'Tržby',
        revenuesSubtitle: 'Podíl tržeb s čerpáním',
        revenuesTotalTitle: 'Tržby celkové',
        revenuesWithCreditTitle: 'Tržby s čerpáním',

        orderTitle: 'Nové objednávky s čerpáním',
        orderNumber: 'Objednávka',
        orderCustomer: 'Zákazník',
        orderCredits: 'Čerpáno',
        orderDate: 'Datum',
        orderTotal: 'Celkem',

        addonStateTitle: 'Stav doplňku',
        goToSettings: 'Nastavení doplňku',

        upgradeMotivationText: 'Vyzkoušejte další efektivní nástroje pro zvýšení výkonu Vašeho e-shopu',
        changeCurrencyText: 'Měna',

        currentPaymentDetail: 'Aktuální platby klientů',
        currentPaymentEshopUrl: 'E-shop',
        currentPaymentContact: 'Kontakt',
        currentPaymentTotal: 'Aktuální úhrada',
        currentPaymentBillingType: 'Typ platby',

        addonBonus: 'Bonus program',
        addonCategories: 'Inteligentní kategorie',
        addonTimeSales: 'Časové slevy',
        addonReview: 'Hodnocení produktu'
    }
}
