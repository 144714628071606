import { CollectionState, CollectionMutations } from './types';

export default function mutationFactory(state: CollectionState): CollectionMutations { 
    function MUTATION_COLLECTION_SET_ITEMS(value: any[]) {
        state.items = [...value];
    }
    function MUTATION_COLLECTION_SET_FIRST(value: any) {
        state.first = value;
    }
    function MUTATION_COLLECTION_SET_LAST(value: any) {
        state.last = value;
    }
    function MUTATION_COLLECTION_SET_PAGE(value: number) {
        state.page =value;
    }
    function MUTATION_COLLECTION_SET_LASTPAGE(value: number) {
        state.lastPage = value;
    }
    function MUTATION_COLLECTION_SET_SORTBY(value: string) {
        state.sortBy = value;
    }
    function MUTATION_COLLECTION_SET_SORTDESC(value: boolean) {
        state.sortDesc = value;
    }
    function MUTATION_COLLECTION_SET_FILTERS(value: any) {
        state.filters = value;
    }
    function MUTATION_COLLECTION_SET_LOADING(value: boolean) {
        state.loading = value;
    }
    function MUTATION_COLLECTION_SET_ITEMSPERPAGE(value: number) {
        state.itemsPerPage = value;
    }
    function MUTATION_COLLECTION_RESET_METADATA(resetFilters: boolean) {
        state.first = null;
        state.last = null;
        state.page = 1;
        state.lastPage = 1;
        state.sortBy = state.defaultSortBy;
        state.sortDesc = state.defaultSortDesc;
        if(resetFilters) {
            state.filters = state.defaultFilters;
        }        
        state.loading = false;
        //itemsperpage not reset
    }

    return {
        MUTATION_COLLECTION_SET_ITEMS,
        MUTATION_COLLECTION_SET_FIRST,
        MUTATION_COLLECTION_SET_LAST,
        MUTATION_COLLECTION_SET_PAGE,
        MUTATION_COLLECTION_SET_LASTPAGE,
        MUTATION_COLLECTION_SET_SORTBY,
        MUTATION_COLLECTION_SET_SORTDESC,
        MUTATION_COLLECTION_SET_FILTERS,
        MUTATION_COLLECTION_SET_LOADING,
        MUTATION_COLLECTION_SET_ITEMSPERPAGE,
        MUTATION_COLLECTION_RESET_METADATA
    }
}