






































import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  model: {
    prop: "show",
    event: "change",
  },

  props: {
    label: String,
    show: Boolean,
    title: String,
    backButton: String,
    confirmButton: String,
  },
});
