import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: { 
            light: {
                primary: '#e01773',
                secondary: '#004d9f', // info darken-3
                third: '#f4f7fc', // gray light
            }
        },
        options: {
            customProperties: true
        },
    },
    breakpoint: {
        mobileBreakpoint: 'sm' // 960
      },
});
