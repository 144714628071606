import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home/index.vue';
import Businesses from '@/views/Businesses/index.vue';
import Shoptet from '@/views/Shoptet/index.vue';
import Tasks from '@/views/Tasks/index.vue';
import News from '@/views/News/index.vue';
import Map from '@/views/Map/index.vue';
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [  
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true,
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
    props: true,
  },
  {
    path: '/shoptet',
    name: 'shoptet',
    component: Shoptet,
    props: true,
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    props: true,
  },
  {
    path: '/businesses',
    name: 'businesses',
    component: Businesses,
    props: true
  },
  {
    path: '/map',
    name: 'map',
    component: Map,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "404" */ '@/views/Login/index.vue'),
    props: (route) => ({ returnUrl: route.query.returnUrl })
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
