

class UtilsService {
  roundNumber(number: number, decimalPlaces: number) {
    const coeficient = Math.pow(10, decimalPlaces);
    return Math.round((number + Number.EPSILON) * coeficient) / coeficient;
  }

}

export default new UtilsService();
