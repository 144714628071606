









































import { defineComponent,  PropType, ref, watch } from "@vue/composition-api";
import utils from "../services/utils";
import type{  TranslateResult } from "vue-i18n";
export default defineComponent({
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    previousValue: {
      type: Number as PropType<number | null>,
      required: true,
    },
    change: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
   
    return {
      utils,
      language: 'cs',
    };
  },
});
