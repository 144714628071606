

















































import { defineComponent, ref, Ref, watch } from "@vue/composition-api";
import firebase from "../../services/firebase";

export default defineComponent({
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    if (this.unsubscribeOrders) {
      this.unsubscribeOrders();
    }
  },
  setup(props, context) {
    const global: any = window;
    const apiKey = "AIzaSyAVCAAVgnIMD2ASIb6PdY7eRznC1aDUDFI";
    const router = context.root.$router;
    const mapElem: Ref<HTMLElement | null> = ref(null);
    let inited = false;
    const initMap = () => {
      class CustomOverlay extends global.google.maps.OverlayView {
        private _position: any;
        private _value: number;
        private _action: string;
        private _div: any;
        private _drawn: boolean;
        private _move: boolean;
        private _bezier = new GmapsCubicBezier();
        private _rechargeSymbol = {
          path: global.google.maps.SymbolPath.CIRCLE,
          scale: 5,
          strokeColor: "#ca29f2",
        };
        private _dischargeSymbol = {
          path: global.google.maps.SymbolPath.CIRCLE,
          scale: 5,
          strokeColor: "#f44336",
        };
        private _minusSymbol = {
          path: global.google.maps.SymbolPath.CIRCLE,
          scale: 5,
          strokeColor: "orange",
        };
        constructor(
          position: any,
          value: number,
          action: string,
          move: boolean
        ) {
          super();
          this._position = position;
          this._value = value;
          this._drawn = false;
          this._action = action;
          this._move = move;
        }

        draw() {
          if (false == this._drawn) {
            this._drawn = true;

            var overlayProjection = this.getProjection();
            var latlng = new global.google.maps.LatLng(
              this._position.lat,
              this._position.lng
            );
            var pixel = overlayProjection.fromLatLngToDivPixel(latlng);

            let markerClass = "";
            if (this._action == "discharge") {
              if (this._value < 0) {
                markerClass = "discharge";
              } else {
                markerClass = "minus";
              }
            } else {
              if (this._value > 0) {
                markerClass = "recharge";
              } else {
                markerClass = "minus";
              }
            }

            this._div = document.createElement("DIV");
            this._div.className = `marker ${markerClass}`;
            this._div.innerHTML = this._value.toFixed(2);

            if (this._move) {
              if (this._value > 0) {
                this.moveCreditSymbol(() => {
                  this.getPanes().overlayLayer.appendChild(this._div);
                  this._div.style.left = pixel.x - 30 + "px";
                  this._div.style.top = pixel.y - 25 + "px";
                });
              } else {
                this.getPanes().overlayLayer.appendChild(this._div);
                this._div.style.left = pixel.x - 30 + "px";
                this._div.style.top = pixel.y - 25 + "px";
                this.moveCreditSymbol(null);
              }
            } else {
              this.getPanes().overlayLayer.appendChild(this._div);
              this._div.style.left = pixel.x - 30 + "px";
              this._div.style.top = pixel.y - 50 + "px";
            }
          }
        }

        private moveCreditSymbol(callback: (() => void) | null) {
          let icon = null;
          if (this._action == "discharge") {
            if (this._value < 0) {
              icon = this._dischargeSymbol;
            } else {
              icon = this._minusSymbol;
            }
          } else {
            if (this._value > 0) {
              icon = this._rechargeSymbol;
            } else {
              icon = this._minusSymbol;
            }
          }
          const p1Position =
            this._value < 0
              ? new global.google.maps.LatLng(ellityHqPos.lat, ellityHqPos.lng)
              : new global.google.maps.LatLng(
                  this._position.lat,
                  this._position.lng
                );
          const p2Position =
            this._value < 0
              ? new global.google.maps.LatLng(
                  this._position.lat,
                  this._position.lng
                )
              : new global.google.maps.LatLng(ellityHqPos.lat, ellityHqPos.lng);
          const lineLength = global.google.maps.geometry.spherical.computeDistanceBetween(
            p1Position,
            p2Position
          );
          const lineHeading = global.google.maps.geometry.spherical.computeHeading(
            p1Position,
            p2Position
          );
          const curveMakerA = global.google.maps.geometry.spherical.computeOffset(
            p1Position,
            lineLength / 3,
            lineHeading - 60
          );
          const curveMakerB = global.google.maps.geometry.spherical.computeOffset(
            p2Position,
            lineLength / 3,
            -lineHeading + 120
          );
          const line = this._bezier.createPolyline(
            p1Position,
            curveMakerA,
            curveMakerB,
            p2Position,
            0.01,
            map,
            icon
          );

          let count = 0;

          let int = window.setInterval(() => {
            count++;

            const icons = line.get("icons");

            icons[0].offset = count / 2 + "%";
            line.set("icons", icons);
            if (count == 200) {
              clearInterval(int);
              line.setMap(null);
              if (callback) {
                callback();
              }
            }
          }, 20);
        }

        onRemove() {
          if (this._div) {
            this._div.parentNode.removeChild(this._div);
            delete this._div;
          }
        }

        append(map: any) {
          this.setMap(map);
          setTimeout(() => {
            this.setMap(null);
          }, 10000);
        }
      }

      class CustomOverlayForOrder extends global.google.maps.OverlayView {
        private _position: any;
        private _total: number;
        private _currency: string;
        private _div: any;
        private _drawn: boolean;
        constructor(position: any, total: number, currency: string) {
          super();
          this._position = position;
          this._total = total;
          this._currency = currency;
          this._drawn = false;
        }

        draw() {
          if (false == this._drawn) {
            this._drawn = true;

            var overlayProjection = this.getProjection();
            var latlng = new global.google.maps.LatLng(
              this._position.lat,
              this._position.lng
            );
            var pixel = overlayProjection.fromLatLngToDivPixel(latlng);

            this._div = document.createElement("DIV");
            this._div.className = `order-marker`;
            this._div.innerHTML = `<span>${this._total.toFixed(0)}</span>`;

            this.getPanes().overlayLayer.appendChild(this._div);
            this._div.style.left = pixel.x - 30 + "px";
            this._div.style.top = pixel.y - 25 + "px";
          }
        }

        onRemove() {
          if (this._div) {
            this._div.parentNode.removeChild(this._div);
            delete this._div;
          }
        }

        append(map: any) {
          this.setMap(map);
          setTimeout(() => {
            this.setMap(null);
          }, 10000);
        }
      }

      class GmapsCubicBezier {
        private B1(t: number) {
          return t * t * t;
        }
        private B2(t: number) {
          return 3 * t * t * (1 - t);
        }
        private B3(t: number) {
          return 3 * t * (1 - t) * (1 - t);
        }
        private B4(t: number) {
          return (1 - t) * (1 - t) * (1 - t);
        }

        public createPolyline(
          latlong1: any,
          latlong2: any,
          latlong3: any,
          latlong4: any,
          resolution: any,
          map: any,
          icon: any
        ) {
          var lat1 = latlong1.lat();
          var long1 = latlong1.lng();
          var lat2 = latlong2.lat();
          var long2 = latlong2.lng();
          var lat3 = latlong3.lat();
          var long3 = latlong3.lng();
          var lat4 = latlong4.lat();
          var long4 = latlong4.lng();

          var points = [];

          for (let it = 0; it <= 1; it += resolution) {
            points.push(
              this.getBezier(
                {
                  x: lat1,
                  y: long1,
                },
                {
                  x: lat2,
                  y: long2,
                },
                {
                  x: lat3,
                  y: long3,
                },
                {
                  x: lat4,
                  y: long4,
                },
                it
              )
            );
          }

          var path = [];
          for (var i = 0; i < points.length - 1; i++) {
            path.push(new global.google.maps.LatLng(points[i].x, points[i].y));
            path.push(
              new global.google.maps.LatLng(
                points[i + 1].x,
                points[i + 1].y,
                false
              )
            );
          }

          return new global.google.maps.Polyline({
            path: path,
            icons: [
              {
                icon,
                offset: "0%",
              },
            ],
            strokeWeight: 0,
            map,
          });
        }

        private getBezier(C1: any, C2: any, C3: any, C4: any, percent: number) {
          var pos: any = {};
          pos.x =
            C1.x * this.B1(percent) +
            C2.x * this.B2(percent) +
            C3.x * this.B3(percent) +
            C4.x * this.B4(percent);
          pos.y =
            C1.y * this.B1(percent) +
            C2.y * this.B2(percent) +
            C3.y * this.B3(percent) +
            C4.y * this.B4(percent);
          return pos;
        }
      }

      const onChange = (
        item: {
          eshopId: string;
          customerId: string;
          change: number;
          category: string;
          message: string;

          metadata: {
            orderDeliveryAddress: string | null;
            orderTotal: number | null;
            orderCurrency: string | null;
          }         
        },
        retries: number
      ) => {
        if (
          (item.category == "order" || item.category == "discharge") &&
          item.metadata.orderDeliveryAddress
        ) {
          geocoder.geocode({ address: item.metadata.orderDeliveryAddress }, function(
            results: any[],
            status: string
          ) {
            if (status == "OK") {
              const location = results[0].geometry.location;
              const overlay = new CustomOverlay(
                { lat: location.lat(), lng: location.lng() },
                item.change,
                item.message,
                true
              );
              overlay.append(map);
            } else if (status == "OVER_QUERY_LIMIT") {
              if (retries-- > 0) {
                setTimeout(() => {
                  onChange(item, retries);
                }, Math.random() * 50000);
                return;
              } else {
                console.error(`${item.metadata.orderDeliveryAddress} could not retry`);
              }
            } else {
              console.error(
                `${item.metadata.orderDeliveryAddress} geocoding error: ${status}`
              );
            }
          });
        } else {
          // const pos = {
          //   lng: map.getBounds().getNorthEast().lng(),
          //   lat: map.getBounds().getSouthWest().lat(),
          // }
          const overlay = new CustomOverlay(
            ellityHqPos,
            item.change,
            item.category,
            false
          );
          overlay.append(map);
        }

        if (item.category == "discharge") {
          dischargeTotal.value += item.change * -1;
        } else {
          rechargeTotal.value += item.change;
        }

        updatePercently();

        lastItems.value = [
          {
            eshopUrl: item.eshopId, // todo
            customerEmail: item.customerId, // todo
            value: item.change.toFixed(2),
            action: item.category,
            detail: item.message,
            total: item.metadata.orderTotal,
            currency: item.metadata.orderCurrency,
            address: item.metadata.orderDeliveryAddress,
          },
          ...lastItems.value,
        ].slice(0, 10);
      };

      const onOrderCreated = (
        item: {        
          eshopId: string;
          customerId: string;
          orderId: string;
          total: number;
          currency: string;
          metadata: {
            deliveryAddress: string | null;
            eshopUrl: string;
          }
        },
        retries: number
      ) => {
        if (item.metadata.deliveryAddress) {
          geocoder.geocode({ address: item.metadata.deliveryAddress }, function(
            results: any[],
            status: string
          ) {
            if (status == "OK") {
              const location = results[0].geometry.location;
              const overlay = new CustomOverlayForOrder(
                { lat: location.lat(), lng: location.lng() },
                item.total,
                item.currency
              );
              overlay.append(map);
            } else if (status == "OVER_QUERY_LIMIT") {
              if (retries-- > 0) {
                setTimeout(() => {
                  onOrderCreated(item, retries);
                }, Math.random() * 50000);
                return;
              } else {
                console.error(
                  `order on ${item.metadata.deliveryAddress} could not retry`
                );
              }
            } else {
              console.error(
                `order on ${item.metadata.deliveryAddress} geocoding error: ${status}`
              );
            }
          });
        }
      };

      if (inited) {
        return;
      }

      inited = true;

      const ellityHqPos = { lat: 49.22755165368869, lng: 17.667209218185388 };

      const map = new global.google.maps.Map(mapElem.value, {
        center: ellityHqPos,
        zoom: 8,
        mapId: "78ec04e626019917",
        disableDefaultUI: true,
      });
      new global.google.maps.Marker({
        position: ellityHqPos,
        map,
        icon: {
          url: "https://mehub-resources.web.app/images/ellity-pikto-gradient-32-32.jpg",
          size: new global.google.maps.Size(32, 32),
          origin: new global.google.maps.Point(0, 0),
          anchor: new global.google.maps.Point(16, 16),
        },
      });

      const geocoder = new global.google.maps.Geocoder();

      firebase
        .admin("getAdminReportingData", {
          reportType: "credit-total",
        })
        .then((result) => {
          // z objednavek do 12.12.2022, kdy byl spusten writer do c#f44336it-changes
          const zeroRecharge = 29367393.15;
          const zeroDischarge = 8638389.37;

          rechargeTotal.value = zeroRecharge + result.data.recharge;
          dischargeTotal.value = zeroDischarge + result.data.discharge;
          updatePercently();

          // debug
          // document.querySelector('.percently')?.addEventListener('click', () => {
          //   onChange({
          //     action: 'order',
          //     value: 100,
          //     customerEmail: 'test',
          //     detail: '1234',
          //     eshopUrl: 'test',
          //     orderCurrency: 'czk',
          //     orderDeliveryAddress: 'Liberec',
          //     orderTotal: 1000
          //   }, 10)
          //   onOrderCreated({
          //     customerEmail: 'test',
          //     eshopUrl: 'test',
          //     currency: 'czk',
          //     deliveryAddress: 'Liberec',
          //     total: 1000
          //   }, 10)
          // })

          if (null == unsubscribe.value) {
            unsubscribe.value = firebase
              .getDb()
              .collection("/bigquery-queue/transaction/records")
              .where("timestamp", ">=", new Date())
              .orderBy("timestamp", "desc")
              .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    onChange(change.doc.data() as any, 1);
                  }
                });
              });
          }

          if (null == unsubscribeOrders.value) {
            unsubscribeOrders.value = firebase
              .getDb()
              .collection("/bigquery-queue/revenue/records")
              .where("timestamp", ">=", new Date())
              .orderBy("timestamp", "desc")
              .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added" && change.doc.data().metadata.isNew) {
                    onOrderCreated(change.doc.data() as any, 1);
                  }
                });
              });
          }
        });
    };

    if (!global.google) {
      global.initMap = initMap;
      let mapScript = document.createElement("script");
      mapScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&libraries=geometry`
      );
      document.head.appendChild(mapScript);
    } else {
      watch(mapElem, () => {
        initMap();
      });
    }

    const updatePercently = () => {
      percently.value = (dischargeTotal.value * 100) / rechargeTotal.value;
    };

    const close = () => {
      router.push({ name: "home" });
    };

    const rechargeTotal = ref(0);
    const dischargeTotal = ref(0);
    const percently = ref(0);

    const unsubscribe: Ref<any> = ref(null);
    const unsubscribeOrders: Ref<any> = ref(null);

    const lastItems: Ref<{
      eshopUrl: string;
      customerEmail: string;
      value: string;
      action: string;
      detail: string | null;
      total: number | null;
      currency: string | null;
      address: string | null;
    }[]> = ref([]);

    return {
      mapElem,
      rechargeTotal,
      dischargeTotal,
      percently,
      close,
      lastItems,
      unsubscribe,
      unsubscribeOrders,
    };
  },
});
